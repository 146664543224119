import React, { useState } from 'react';
import { Box, Text, } from '@chakra-ui/react';
import MySvgComponent from './MySvgComponent';
import NFTcard from './NFTcard';
import Coincard from './Coincard';

const backgroundStyle = {
    width: "100vw",
    backgroundColor: "#1C0D28",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    position: "relative",
    overflowX: "hidden",
    overflowY: "hidden",
    css: {
        '@supports not (-webkit-touch-callout: none)': {
            width: '100vw'
        }
    }
};

const TEXT_STYLES = {
    color: "whiteAlpha.900",
    fontSize:{ base: "24px", sm: "28px", md: "36px", lg: "42px" },
    fontFamily: "'Lexend Peta', sans-serif"
};  

const TEXT_STYLES2 = {
    color: "whiteAlpha.900",
    fontSize: { base: "14px", sm: "14px", md: "18px", lg: "22px" },
    fontFamily: "'Lexend mega', sans-serif"
};

const TEXT_STYLES9 = {
    color: "whiteAlpha.700",
    fontSize: { base: "14px", sm: "14px", md: "18px", lg: "14px" },
    fontFamily: "'Lexend mega', sans-serif",
    fontStyle: "italic"
};

const TEXT_STYLES7 = {
    color: "whiteAlpha.900",
    fontSize:{ base: "24px", sm: "28px", md: "36px", lg: "42px" },
    fontFamily: "'Lexend Peta', sans-serif"
};  


const Ecosystem = () => {

    // State to manage popup visibility
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    // Function to toggle the popup
    const handleBoxClick = () => {
        setIsPopupOpen(!isPopupOpen);
    };

    const Popup = () => (
        <Box style={{
            position: 'fixed', // Use 'fixed' to position relative to the viewport
            top: '50%', // Center vertically
            left: '50%', // Center horizontally
            transform: 'translate(-50%, -50%)', // Adjust position to truly center
            width: '95%', // Set width of the popup (increased from 80%)
            maxWidth: '700px', // Max width for larger screens (increased)
            zIndex: 1000, // High z-index to be on top of everything
            backgroundColor: '#301644', // Updated background color
            padding: '10px', // Padding inside the popup
            borderRadius: '10px', // Optional: for rounded corners
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)' // Optional: for shadow effect
        }}>
            <img src="../Ecomap.png" alt="Ecosystem" style={{ width: '100%', height: 'auto' }}/>
            <button onClick={() => setIsPopupOpen(false)} style={{ marginTop: '10px', color: 'white' }}>Close</button>
        </Box>
    );      

    return (

    <Box {...backgroundStyle} id="Ecosystem">
        <Box
            display="flex" 
            justifyContent="center" 
            alignItems="center"
            pb={{base: "5rem", sm: "5rem", md: "6rem", lg: "7rem"}}
        >
                <Box
                    w="full"
                    bg="#1C0D28"
                    borderRadius="16px"
                    pt={{base: "3rem", sm: "4rem", md: "5rem", lg: "6rem"}}
                >
                   <Text pt="1rem" {...TEXT_STYLES} textAlign="center">ECOSYSTEM</Text>
                   <Text pt="0.3rem" {...TEXT_STYLES2} textAlign="center">Explore our revenue flow chart here.</Text>
                   <Text pt="0.3rem" {...TEXT_STYLES9} textAlign="center">We highly recommend reviewing it for better insight.</Text>
                   <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        w="full" 
                        h="auto" 
                    >
                        <Box position="relative" w="300px" h="auto">
                            {/* Your gradient border box */}
                            <MySvgComponent
                                w="300px" 
                                _hover={{ transform: 'scale(1.05)' }} // Scale up the box on hover
                                transition="transform 0.2s" 
                                onClick={handleBoxClick}
                                cursor="pointer" 
                                svgText="Learn more"     
                            />               
                            {isPopupOpen && <Popup />}
                        </Box>
                    </Box>

                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        pt={{base: "2rem", sm: "3rem", md: "4rem", lg: "4.5rem"}}
                        w="full" 
                        h="auto" 
                    >
                        <Box>
                            {/* Text for base up to sm */}
                            <Text pt="1rem" {...TEXT_STYLES7} display={{ base: "block", sm: "none" }} textAlign="center">
                                NFTs & TOKEN
                            </Text>

                            {/* Text for sm and up */}
                            <Text pt="1rem" {...TEXT_STYLES7} display={{ base: "none", sm: "block" }} textAlign="center">
                                NFTs & ANGRY TOKEN
                            </Text>
                            <Box
                                display="flex"
                                justifyContent={{ base: "flex-start", md: "center"}}
                                alignItems={{ base: "center", sm: "center", md: "center", lg: "center" }}
                                h={{ base: "auto", sm: "auto", md: "auto", lg: "auto" }} 
                                flexDirection={{ base: "column", lg: "row" }}
                                pt={{base:"1.5rem", sm:"1.5rem", md:"1.5rem", lg:"1.5rem"}}
                                gap={{ base: "2rem", md: "2.5rem" }}
                            >       
                                <NFTcard/>
                                <Coincard/>                         
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
export default Ecosystem;
