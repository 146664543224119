import React, { useState, useEffect} from 'react';
import { Box, Text, Flex, Image, useBreakpointValue, 
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon, Link
  } from '@chakra-ui/react';
import { IoIosReturnLeft } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import OrientationWarning from './Rotate';
import {useMediaQuery, Spinner} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import ThreeDModel from './ThreeDModel'; 

const Token = () => {

    const [isSmallScreen] = useMediaQuery("(max-width: 768px)");

    const fontSize = useBreakpointValue({ base: '1em', sm: '1.3em' });
    const fontSize2 = useBreakpointValue({ base: '0.8em', sm: '1em' });

    const TEXT_STYLES3 = {
        color: "whiteAlpha.800",
        fontSize: { base: "14px", sm: "15px", md: "16px", lg: "18px" },
    };

    const TEXT_STYLES = {
        color: "whiteAlpha.900",
        fontSize: { base: "24px", sm: "28px", md: "32px", lg: "36px" },
        fontWeight: "black",
        fontFamily: "'Lexend Peta', sans-serif"
    };

    const shadowColor = '#361D4A';
    const boxShadowValue = `0 4px 32px ${shadowColor}`;

    const gradientBorderStyles = useBreakpointValue({
        base: { width: '260px', height: '80px', padding: '0.8px', borderRadius: "16px", boxShadow: boxShadowValue},
        sm: { width: '370px', height: '100px', padding: '0.8px', borderRadius: "16px", boxShadow: boxShadowValue},
        md: { width: '370px', height: '100px', padding: '0.8px', borderRadius: "16px", boxShadow: boxShadowValue},
        lg: { width: '370px', height: '100px', padding: '0.8px', borderRadius: "16px", boxShadow: boxShadowValue},
       
    });
    
    const svgStyles = {
        base: { position: 'relative', width: '100%', height: '100%', borderRadius: "16px" },
        sm: { position: 'relative', width: '100%', height: '100%', borderRadius: "16px" },
        md: { position: 'relative', width: '100%', height: '100%', borderRadius: "16px" },
        lg: { position: 'relative', width: '100%', height: '100%', borderRadius: "16px" },
        
    };
    
    const currentSvgStyles = useBreakpointValue(svgStyles);
    
    const navigate = useNavigate();

    const handleReturnClick = () => {
        navigate('/');
    };

    const linkStyle = {
        p: "2",
        borderRadius: "md",
        _hover: {
            bg: "#461874",
            borderRadius: "md",
        },
    };

    // Determine the media URL based on the browser

    const [showTooltip, setShowTooltip] = useState(false);

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText("enwveismseiieses");
        setShowTooltip(true);
        setTimeout(() => setShowTooltip(false), 2000); // Tooltip disappears after 2 seconds
    };

    const accordionItemStyles = {
        marginBottom: '1rem', // Space between accordion items
        backgroundColor: 'transparent', // Ensure the background is transparent
        border: '1px solid white', // White border
        borderRadius: '8px', // Rounded corners
        _last: {
          marginBottom: 0, // Remove bottom margin for the last item
        },
      };
      
    const accordionButtonStyles = {
        py: '1.5rem', // Vertical padding inside the accordion button
        px: '1.5rem', // Horizontal padding inside the accordion button
        textAlign: 'left',
        borderRadius: '8px',
        fontSize: { base: "16px", sm: "18px", md: "20px" },
        fontWeight: 'normal', // If you want the font weight to be normal
        _hover: {
          bg: 'transparent', // Background color on hover
        },
        _expanded: {
          bg: 'purple.600', // Background color when the item is expanded
          color: 'white',
        },
      };
    
      const [isLoading, setIsLoading] = useState(true);
      // Framer Motion variants for fade-out effect
      const fadeOut = {
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
          exit: { opacity: 0 }
      };
    
      useEffect(() => {
          const timer = setTimeout(() => {
              setIsLoading(false);
          }, 1000);
          return () => clearTimeout(timer);
      }, []);
    
      const TEXT_STYLES7 = {
          color: "whiteAlpha.900",
          fontSize: { base: "30px", sm: "34px", md: "36px", lg: "42px" },
          fontWeight: "black",
          fontFamily: "'Lexend Peta', sans-serif"
      };
      
    
      if (isLoading) {
        return (
            <motion.div
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={fadeOut}
                transition={{ duration: 0.5 }}
            >
                <Box
                    w="100vw"
                    h="100vh"
                    bg="#1C0D28"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Text {...TEXT_STYLES7}>Before it 🚀</Text>
                    <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="purple.400" size="xl" mt="4"/>
                </Box>
            </motion.div>
        );
    }  

    return (
        <Box
            w="100vw"
            minW="100vw"
            minH="100vh"
            bg="#1C0D28"
            bgPos="center"
            bgSize="cover"
            bgRepeat="no-repeat"
            position="relative"
            overflowX="hidden"
            overflowY="hidden"
            css={{
                '@supports not (-webkit-touch-callout: none)': {
                    width: '100vw',
                }
            }}
        >
            {isSmallScreen && <OrientationWarning />}
            {/* Navigation */}
            <Flex
                as="nav"
                align="center"
                justify="space-between"
                wrap="wrap"
                padding="1.5rem"
                bg="#1C0D28"
                color="white"
                position="absolute"
                width="full"
                zIndex={2}
            >
                <Box position="relative" ml={{ base: '0rem', sm: "1rem" ,md: '1rem', lg: '2.7rem' }}  mt={{base: '0.3rem', sm: '0rem'}}>
                    <Image
                        src="/icon.png"
                        w={{ base: '45px', sm: '50px' }}
                        h={{ base: '45px', sm: '50px' }}
                    />
                    <Box
                        position="absolute"
                        top="0"
                        left="0"
                        right="0"
                        bottom="0"
                        cursor="pointer"
                        onClick={handleReturnClick}
                    />
                </Box>
                <Box {...linkStyle} onClick={handleReturnClick} mr={{ base: '0rem', sm: '-0.8rem', md: '-0.4rem', lg: '1.8rem' }}>
                    <IoIosReturnLeft size="36px" />
                </Box>
            </Flex>

        {/* Content Section */}
        <Flex direction="column" align="center" mt="7rem" mb="4rem">
            <Text {...TEXT_STYLES} mt="4rem">ANGRY DAO</Text>
            <Box mt="1rem" mx="auto" width="fit-content" maxWidth="1200px">
                <Text {...TEXT_STYLES3} textAlign="center" ml="2.5rem" mr="2.5rem">
                    Welcome to ANGRY DAO, an innovative platform where every individual is empowered to engage with our project. We invite you to delve into the DAOs explorer on 
                    <Link href="https://app.aragon.org/#/daos/polygon/0xf9cd0745ab3f2e96faebe150f91c5952dc67b910/dashboard" color="blue.400" isExternal>
                       {''} ARGON
                    </Link>, 
                    where you can immerse yourself in a variety of tasks, accumulate tokens, and become an integral part of our community.
                </Text>
            </Box>
        </Flex>

            {/* Content Section */}
            <Flex direction="column" align="center" mt="6rem" mb="4rem">
                {/* $ANGRY TOKEN Section */}
                <Text {...TEXT_STYLES} mt="4rem"  position="absolute">$ANGRY TOKEN</Text>
                <Box width="100%" display="flex" justifyContent="center" mt="4rem">
                <ThreeDModel />
                </Box>

                <Flex direction={{ base: "column", md: "row" }} justify="center" align="center" gap="2rem">
                    <Box sx={gradientBorderStyles} bgGradient="linear(to right, #9B9292 0%, rgba(255, 255, 255, 0.29) 50%, #9B9292 100%)">
                        <Box p="1rem" sx={{ ...currentSvgStyles, display: 'flex', alignItems: 'center' }} bg="#1C0D28">
                            <Image 
                                src="../i1.png" 
                                alt="Description of Image"
                                width={{ base: '45px', sm: '65px' }}  // Adjust the values as needed for different breakpoints
                                height={{ base: '40px', sm: '60px' }} // Same for height
                                style={{marginRight: "15px"}}  
                            />
                                
                            <Text>
                                <span style={{ fontSize: fontSize, color: 'rgba(255, 255, 255, 0.9)'}}>Utilized</span>
                                <span style={{ fontSize: fontSize2, color: 'rgba(255, 255, 255, 0.4)' }}> across the entire platform.</span>
                            </Text>
                        </Box>
                    </Box>

                    <Box sx={gradientBorderStyles} bgGradient="linear(to right, #9B9292 0%, rgba(255, 255, 255, 0.29) 50%, #9B9292 100%)">
                        <Box p="1rem" sx={{ ...currentSvgStyles, display: 'flex', alignItems: 'center' }} bg="#1C0D28">
                            <Image 
                                src="../i2.png" 
                                alt="Description of Image"
                                width={{ base: '45px', sm: '65px' }}  // Adjust the values as needed for different breakpoints
                                height={{ base: '40px', sm: '60px' }} // Same for height
                                style={{marginRight: "10px"}}   
                            />
                            <Text>
                                <span style={{ fontSize: fontSize, color: 'rgba(255, 255, 255, 0.9)' }}>DAOs</span>
                                <span style={{ fontSize: fontSize2, color: 'rgba(255, 255, 255, 0.4)' }}> protocol, when anyone can have power.</span>
                            </Text>
                        </Box>
                    </Box>

                    <Box sx={gradientBorderStyles} bgGradient="linear(to right, #9B9292 0%, rgba(255, 255, 255, 0.29) 50%, #9B9292 100%)">
                        <Box p="1rem" sx={{ ...currentSvgStyles, display: 'flex', alignItems: 'center' }} bg="#1C0D28">
                            <Image 
                                src="../i3.png" 
                                alt="Description of Image"
                                width={{ base: '43px', sm: '60px' }}  // Adjust the values as needed for different breakpoints
                                height={{ base: '38px', sm: '55px' }} // Same for height
                                style={{marginRight: "15px"}}  
                            />
                            <Text>
                                <span style={{ fontSize: fontSize, color: 'rgba(255, 255, 255, 0.9)' }}>One-time</span>
                                <span style={{ fontSize: fontSize2, color: 'rgba(255, 255, 255, 0.4)' }}> release of 100m supply.</span>
                            </Text>
                        </Box>
                    </Box>
                </Flex>

                {/* DISTRIBUTION Section */}
                <Text {...TEXT_STYLES} mt="6rem">DISTRIBUTION</Text>
                <Box width="100%" display="flex" justifyContent="center">
                    <Image src="/Tokenomics.png" alt="Tokenomics" width={{base: "90%", sm: "90%", md: "100%"}} style={{ maxWidth: '750px', objectFit: 'cover' }} mt="2rem" mr={{base: "-6rem", sm: "-8rem" , md: "-8rem" }}/>
                </Box>
            </Flex>

            <Flex direction="column" align="center" mt="4rem">
                <Text {...TEXT_STYLES} color="whiteAlpha.900">FAQs</Text>
                <Accordion width={{ base: "80%", md: "70%" }} allowMultiple mt="1.5rem">

                    <AccordionItem sx={accordionItemStyles}>
                        <AccordionButton sx={accordionButtonStyles}>
                            <Box flex="1" textAlign="left" color="whiteAlpha.800" fontSize={{ base: "14px", sm: "16px", md: "18px" }}>
                                What do I need to know?
                            </Box>
                            <AccordionIcon color="white" />
                        </AccordionButton>
                        <AccordionPanel pb={4} color="whiteAlpha.900" fontSize={{ base: "14px", sm: "16px", md: "18px" }}>
                        <p style={{ marginBottom: '1em', lineHeight: '1.5em', textIndent: '2em' }}>
                         •          $ANGRY TOKEN stands at the heart of our diverse ecosystem, integrating seamlessly across all our products – from Casino, Defi and NFT Marketplace. While users can freely operate with the native network token (Matic), opting for $ANGRY TOKEN unlocks exclusive perks and incentives, enhancing the user experience.
                        </p>
                        <p style={{ marginBottom: '1em', lineHeight: '1.5em', textIndent: '2em'}}>
                            As a cornerstone of our platform, $ANGRY TOKEN is more than just a transactional asset; it embodies user empowerment. Token holders are not merely investors but active participants in shaping the platform's future. With governance rights, each holder's voice contributes to the project's development direction, ensuring a community-driven evolution.
                        </p>
                        <p style={{ marginBottom: '1em', lineHeight: '1.5em', textIndent: '2em' }}>
                        With a one-time release of a maximum supply of 100 million, $ANGRY TOKEN is designed to uphold equity and authority for its holders. With the DAO's voting protocol, we ensure fairness and trust.
                        </p>
                        <p style={{ marginBottom: '1em' }}>
                            $ANGRY TOKEN – Where power is from everyone.
                        </p>
                        </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem sx={accordionItemStyles}>
                        <AccordionButton sx={accordionButtonStyles}>
                            <Box flex="1" textAlign="left" color="whiteAlpha.800" fontSize={{ base: "14px", sm: "16px", md: "18px" }}>
                            Token details?
                            </Box>
                            <AccordionIcon color="white" />
                        </AccordionButton>
                        <AccordionPanel pb={4} color="whiteAlpha.900" fontSize={{ base: "14px", sm: "16px", md: "18px" }}>
                            <p style={{ marginBottom: '1em' }}>• Max supply: 100,000,000 ANGRY</p>
                        
                            <p style={{ marginBottom: '1em' }}>
                            • Polygon chain (
                                <a 
                                    href="https://polygon.technology/blog/getting-started-with-metamask-on-polygon" 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                >
                                    How to add network on 
                                    <span style={{ color: '#007aff' }}> Metamask</span>
                                </a>
                                )
                            </p>

                            <p style={{ marginBottom: '0.3em' }}>• How to add $ANGRY Token to Metamask?</p>
                                <p style={{ paddingLeft: '20px', marginBottom: '0.3em'}}>• Click on Tokens section.</p>
                                <p style={{ paddingLeft: '20px', marginBottom: '0.3em' }}>• Click "Import tokens".</p>
                                <p style={{ paddingLeft: '20px', marginBottom: '0.3em' }}>
                                • Input Token contract: 
                                <span 
                                    style={{ color: '#007aff', cursor: 'default', position: 'relative' }} 
                                    onClick={handleCopyToClipboard}
                                    title="Click to copy"
                                >
                                    {' '}0xc107DDA4Ac49e519a558152E78F79e878807f1Fd
                                    {showTooltip && (
                                        <span style={{
                                            position: 'absolute',
                                            left: '100%',
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            backgroundColor: 'black',
                                            color: 'white',
                                            padding: '2px 8px',
                                            borderRadius: '4px',
                                            fontSize: '12px',
                                            marginLeft: '5px'
                                        }}>
                                            Copied!
                                        </span>
                                    )}
                                </span>
                                    </p>
                                <p style={{ paddingLeft: '20px', marginBottom: '0.3em' }}>• Symbol: $ANGRY</p>
                                <p style={{ paddingLeft: '20px'}}>• Decimals: TBA</p>
                        </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem sx={accordionItemStyles}>
                        <AccordionButton sx={accordionButtonStyles}>
                            <Box flex="1" textAlign="left" color="whiteAlpha.800" fontSize={{ base: "14px", sm: "16px", md: "18px" }}>
                              Token distribution.
                            </Box>
                            <AccordionIcon color="white" />
                        </AccordionButton>
                        <AccordionPanel pb={4} color="whiteAlpha.900" fontSize={{ base: "14px", sm: "16px", md: "18px" }}>
                                <p style={{ marginBottom: '1em'}}>• 26.01% to Founders</p>
                                <p style={{ marginBottom: '1em' }}>• 24.99% to Stakeholders</p>
                                <p style={{ marginBottom: '1em'}}>• 49%(of 100B) to Treasury</p>
                                <p style={{ fontStyle: 'italic', marginBottom: '1em' }}> The allocations are subject to change and require approval from the DAOs.</p>
                        </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem sx={accordionItemStyles}>
                        <AccordionButton sx={accordionButtonStyles}>
                            <Box flex="1" textAlign="left" color="whiteAlpha.800" fontSize={{ base: "14px", sm: "16px", md: "18px" }}>
                            Token event calendar.
                            </Box>
                            <AccordionIcon color="white" />
                        </AccordionButton>
                        <AccordionPanel pb={4} color="whiteAlpha.900" fontSize={{ base: "14px", sm: "16px", md: "18px" }}>
                            <p style={{ marginBottom: '1em' }}> • Q1: Distribution among ANGRY DAO.</p>
                            <p style={{ marginBottom: '1em' }}> • Q2: Token staking.</p>
                            <p style={{ marginBottom: '1em' }}> • Q3: Start integrating into all ecosystems.</p>
                            <p style={{ marginBottom: '1em' }}> • Q4: Planning to get listed on an CEX (Centralized exchange).</p>
                            <p style={{ fontStyle: 'italic', marginBottom: '1em' }}> The schedules are subject to change and require approval from the DAOs.</p>
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            </Flex>
            
            <Footer />
        </Box>
    );
};

export default Token;
