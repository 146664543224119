import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Button,
  VStack,
} from '@chakra-ui/react';
import Typed from 'react-typed';
import Navbar from './Navbar';

const Hero = () => {

    const [bgImage, setBgImage] = useState("url('/Herobg4.png')");

    useEffect(() => {
        const intervalId = setInterval(() => {
            setBgImage(current => (current === "url('/Herobg4.png')" ? "url('/Herobg3.png')" : "url('/Herobg4.png')"));
        }, 4000); // Switch images every 1 second (1000 milliseconds)

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, []);
    
    const scrollToEcosystem = () => {
      const ecosystemElement = document.getElementById('Ecosystem');
      if (ecosystemElement) {
          ecosystemElement.scrollIntoView({ behavior: 'smooth' });
      }
    };

  return (
    <>
    <Box
      w="100vw"
      h="auto"
      bgImage={bgImage}
      bgPos="center"
      bgSize="cover"
      bgRepeat="no-repeat"
      position="relative"
      overflowX="hidden"
      css={{
        '@supports (-webkit-fill-available)': {
          width: '-webkit-fill-available',
          height: '-webkit-fill-available',
          minHeight: '-webkit-fill-available',
        },
        '@supports not (-webkit-fill-available)': {
          width: '100vw',
          height: '100vh',
          minHeight: '100vh',
        },

        overflowY: 'hidden', // Prevent vertical scrolling
      }}
    >
      <Navbar/>
      <Flex
        position="absolute"
        top={{ base: "130px", md: "50%" }} // Move higher on mobile screens, keep at 50% for medium and larger devices
        transform="translateY(-50%)" // Adjust for the height of the text
        ml={{ base: '1rem', md: '1rem', lg: '1rem' }}
      >
        <VStack
          align="left" // Align text to the left
          spacing={0} // No space between stack items
          ml={{ base: '0rem', sm: '1rem', lg: '3rem' }}
          mt={{base: "2rem", sm: "5rem", md: "0rem"}}
        >
          <Box as="span" fontSize={{ base: "36px", md: "64px", lg: "64px" }} color="whiteAlpha.900" fontWeight="black" fontFamily="'Lexend Peta', sans-serif">
            ANGRY
          </Box>
          <Box as="span" fontSize={{ base: "36px", md: "64px", lg: "64px" }} color="whiteAlpha.900" fontWeight="black" fontFamily="'Lexend Peta', sans-serif">
          <Typed
              strings={['WEB3', 'NFT', 'DAPP', 'HUB', 'DAO']}
              typeSpeed={140}
              backSpeed={165}
              loop
            />
          </Box>
        </VStack>
        </Flex>
        <Flex
          position="absolute"
          top={{ base: "75%", md: "50%" }} // Position lower on mobile screens, keep at 50% for medium and larger devices
          right={{ base: '1rem',md: '1.6rem', lg: '3.7rem' }}  // Align to the right side, with responsive margin
          transform={{ base: "translateY(-75%)", md: "translateY(-50%)" }} // Adjust vertical centering on mobile
          pb={{sm: "1rem"}}
        >
            <VStack
              zIndex={1}
              align="right" // Align text to the right
              spacing={0} // No space between stack items
              mb={{base: "0rem", sm:"3rem", md:"0rem"}}
              mr={{base: "0rem", sm:"1rem", md:"0rem"}}
            >
              <Box as="span" fontSize={{ base: "24px", md: "40px", lg:"40px" }} color="whiteAlpha.900" fontWeight="extrabold" fontFamily="'Lexend Peta', sans-serif">
                One-Stop
              </Box>
              <Box as="span" fontSize={{ base: "24px", md: "40px", lg:"40px" }} color="whiteAlpha.900" fontWeight="extrabold" fontFamily="'Lexend Peta', sans-serif">
                Ecosystem
              </Box>
            </VStack>
          </Flex>
          
          <Flex
            position="absolute"
            left="50%" // Center horizontally
            bottom={{base:"23rem", sm:"25rem"}}
            transform={{ base: "translate(-50%, 250%)", sm: "translate(-50%, 250%)", md: "translate(-50%, 250%)", lg: "translate(-50%, 250%)" }} // Adjust for the width and height of the Flex box
            padding="1rem"
            justifyContent="center" // Center items horizontally inside the Flex
            alignItems="center" // Center items vertically inside the Flex
            zIndex={0}
            gap={{ base: '5vw', md: '3vw' }}
        >
  {/* JOIN US Button Layer */}
  <Box
    position="relative"
    w={{ base: "127.28px",md: "184px", lg: "184px"}}
    h={{ base: "60.28px", md: "74px", lg: "74px" }}
    bgGradient="linear(to-r, #D83A56, #92513C)"
    borderRadius="16px"
    zIndex={0}
    opacity="1"
  >
    <Button
      position="absolute"
      top="-6px" // Adjust to create the effect of depth
      bgGradient="linear(to-r, #F84565, #F88965)"
      color="whiteAlpha.900"
      fontSize={{ base: "16px", md: "20px", lg: "20px" }}
      fontWeight="semibold"
      fontFamily="'Lexend Peta', sans-serif"
      borderRadius="16px"
      w="100%"
      h="100%"
      _hover={{
        bgGradient: "linear(to-r, #F97575, #F89B75)"
      }}
      _active={{
        top: "0px", // Button appears to move down when pressed
        left: "0px", // Button appears to move down when pressed
        bgGradient: "linear(to-r, #F83555, #F87955)"
      }}
      zIndex={1}
      onClick={() => window.open('https://www.angryroll.com', '_blank')}
    >
      CASINO
    </Button>
  </Box>

  {/* EXPLORE Button Layer */}
  <Box
    position="relative"
    w={{ base: "127.28px",md: "184px", lg: "184px"}}
    h={{ base: "60.28px", md: "74px", lg: "74px" }}
    backgroundColor="#2C0850"
    borderRadius="16px"
    zIndex={0}
    opacity="1"
  >
    <Button
      position="absolute"
      top="-6px" // Adjust to create the effect of depth
      left="0px" // Adjust to create the effect of depth
      bgGradient="linear(to-r, #461874, #7616D6)"
      color="whiteAlpha.900"
      fontSize={{ base: "16px",md: "20px", lg: "20px"}}
      fontWeight="semibold"
      fontFamily="'Lexend Peta', sans-serif"
      borderRadius="16px"
      w="100%"
      h="100%"
      _hover={{
        bgGradient: "linear(to-r, #573184, #7A57D6)" //
      }}
      _active={{
        top: "0px", // Button appears to move down when pressed
        left: "0px", // Button appears to move down when pressed
        bgGradient: "linear(to-r, #3a105e, #5A379D)"
      }}
      zIndex={1}
      onClick={scrollToEcosystem}
    >
      EXPLORE
    </Button>
  </Box>
</Flex>
       </Box>
    </>
  );
};

export default Hero;