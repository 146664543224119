import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme'; // Make sure to import your custom theme
import './App.css'; // Adjust the path as necessary
import { ThirdwebProvider } from "@thirdweb-dev/react";
import { Analytics } from '@vercel/analytics/react';

const activeChain = "mumbai";

// Wrap your App component with ChakraProvider and pass the theme prop
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThirdwebProvider activeChain={activeChain} clientId={process.env.NEXT_PUBLIC_TEMPLATE_CLIENT_ID}>
    <ChakraProvider theme={theme}> {/* Pass the theme prop here */}
      <App />
      <Analytics />
    </ChakraProvider>
    </ThirdwebProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
