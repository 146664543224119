import React from 'react';
import { Box, Text, Link, useBreakpointValue, Image} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

const NFTcard = () => {
  // Use the `useBreakpointValue` hook for responsive styles
  const fontStyles = useBreakpointValue({
    base: { fontSize: '18px'},
    sm: { fontSize: '20px'},
    md: { fontSize: '24px'},
    lg: { fontSize: '28px'},
  });

  const subtitleFontSizes = useBreakpointValue({
    base: { fontSize: '11px'},
    sm: { fontSize: '15px'},
    md: { fontSize: '16px'},
    lg: { fontSize: '17px'},
  });

  const MintSizes = useBreakpointValue({
    base: { fontSize: '11px'},
    sm: { fontSize: '15px'},
    md: { fontSize: '16px'},
    lg: { fontSize: '17px'},
  });

  const shadowColor = '#361D4A';
  const boxShadowValue = `0 4px 32px ${shadowColor}`;
  
  const gradientBorderStyles = useBreakpointValue({
    base: { width: '80vw', height: '16rem', padding: '1px', borderRadius: "16px", boxShadow: boxShadowValue},
    sm: { width: '350px', height: '230px', padding: '1px', borderRadius: "16px", boxShadow: boxShadowValue},
    md: { width: '500px', height: '280px', padding: '1px', borderRadius: "16px", boxShadow: boxShadowValue},
    lg: { width: '450px', height: '290px', padding: '1px', borderRadius: "16px", boxShadow: boxShadowValue},
    xl: { width: '450px', height: '300px', padding: '1px', borderRadius: "16px", boxShadow: boxShadowValue},
  });

  const svgStyles = useBreakpointValue({
    base: { position: 'relative', width: '100%', height: '100%', borderRadius: "16px" },
    sm: { position: 'relative', width: '100%', height: '100%', borderRadius: "16px" },
    md: { position: 'relative', width: '100%', height: '100%', borderRadius: "16px" },
    lg: { position: 'relative', width: '100%', height: '100%', borderRadius: "16px" },
    xl: { position: 'relative', width: '100%', height: '100%', borderRadius: "16px" },
  });

  const marginTopValue = useBreakpointValue({ base: '2rem', sm: '3rem' });

  const imageStyle = {
    position: 'absolute', // Position the image
    width: 'auto', // Adjust width as needed
    height: '70%', // Adjust height as needed
    zIndex: 1, // Ensure the image is below the text
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)', // Center the image
  };

  return (
    <Box style={gradientBorderStyles} bgGradient="linear(to right, #9B9292 0%, rgba(255, 255, 255, 0.29) 50%, #9B9292 100%)">

      <Box style={svgStyles} bg="#1C0D28" overflow="hidden">

      <Image src="./Tokenbg.png" alt="Token Image" style={imageStyle}/>

        <Box padding={{ base: '1.5rem', sm: '2.5rem', md: '3rem', lg: '3rem', xl: '3rem' }} textAlign="left" position="absolute" zIndex="2">
          <Text color="whiteAlpha.900" style={fontStyles}>DAOs</Text>
          <Text color="whiteAlpha.800" style={subtitleFontSizes}>Explore token distribution and step in to be a part of DAOs.</Text>
          <Text color="whiteAlpha.900" style={{ ...MintSizes, marginTop: marginTopValue }}>
            <Link as={RouterLink} to="/Token">
            Unlock Now →
            </Link>
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default NFTcard;
