import React, { useRef } from 'react';
import {
  Flex,
  Image,
  Stack,
  Button,
  IconButton,
  Link,
  Box,
  useDisclosure
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { Link as RouterLink } from 'react-router-dom';


const Navbar = () => {
  const { isOpen, onToggle } = useDisclosure();
  const sidebarRef = useRef();
  
  const scrollToProducts = () => {
    const productsElement = document.getElementById('Products');
    if (productsElement) {
        productsElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const linkStyle = {
    fontSize: "16px",
    fontWeight: "semibold",
    color: "white",
    fontFamily: "'Lexend Peta', sans-serif",
    p: "3.5", // Padding, adjust as needed
    borderRadius: "3xl", // Rounded corners, adjust as needed
    _hover: {
      bg: "#461874", // Background color on hover
      borderRadius: "3xl", // Ensure rounded corners on hover
    },
  };

  return (
    <Box>
      {/* Navigation Bar */}
      <Flex
        as="nav"
        align="center"
        justify="space-between"
        wrap="wrap"
        padding="1.5rem"
        bg="transparent"
        color="white"
        position="absolute"
        width="full"
        zIndex={2}
      >
      
        {/* Logo/Icon */}
        <Image
          src="/icon.png" // The path is relative to the public directory
          w={{ base: '45px', sm: '50px' }} // Responsive width
          h={{ base: '45px', sm: '50px' }} // Responsive height
          mt={{base: '0.3rem', sm: '0rem'}}
          ml={{ base: '0rem', sm: "1rem" ,md: '1rem', lg: '2.5rem' }}
        />

          {/* Navigation Links for larger screens */}
          <Stack
            direction={{ base: 'column', md: 'row' }}
            display={{ base: 'none', lg: 'flex' }}
            width={{ base: 'full', md: 'auto' }}
            alignItems="center"
            mt={{ base: 4, md: 0 }}   
            spacing={{ base: '4', md: '12' }}
            mr={{ base: '1rem', md: '1rem', lg: '2.5rem' }}
           >

          <Link as={RouterLink} to="/nfts" {...linkStyle}>NFTs</Link>
          <Link href="https://angry-web3.gitbook.io/angry-web3-litepaper/" {...linkStyle} target="_blank" rel="noopener noreferrer">Litepaper</Link>

       
           {/* <Link as={RouterLink} to="/Aboutus" {...linkStyle}>About</Link> */} 
          <Button
              bg="#F84565"
              color="white"
              fontSize="16px"
              w="150px"
              h="50px"
              fontWeight="semibold"
              fontFamily="'Lexend Peta', sans-serif"
              borderRadius="16px"
              _hover={{ bg: "#f96d7e" }} // Slightly lighter on hover
              _active={{ bg: "#f73855" }} // Slightly darker on active
              onClick={scrollToProducts}
              sx={linkStyle}
           >
            Products
          </Button>
        </Stack>
      </Flex>

      <Box pt={{base: "2rem", sm: "2rem", lg: "0rem"}}>
          {/* Hamburger Icon for smaller screens */}
          <IconButton
            display={{ base: 'block', lg: 'none' }}
            icon={isOpen ? <CloseIcon color="white" /> : <HamburgerIcon color="white" />}
            onClick={onToggle}
            variant="outline"
            aria-label="Open Menu"
            zIndex={21}
            size="3xl"
            width="45px" // Increase the width
            height="45px" // Increase the height
            ml="auto"
            mr={{ base: '1rem', sm: '2rem'}}
            borderRadius="8px"
          />
        <Box
          ref={sidebarRef}
          display={{ base: 'flex', lg: 'none' }}
          position="fixed"
          top="0" // Align to the top
          right="0" // Align to the right side
          height="100vh"
          width={{base: "180px", sm:"250px"}}
          bg="rgba(0, 0, 0, 0.5)"
          zIndex={isOpen ? 20 : -1} // Higher z-index when open
          overflowY="auto"
          transform={isOpen ? "translateX(0)" : "translateX(100%)"}
          transition="transform 0.2s ease-in-out"
          css={{
            backdropFilter: 'blur(10px)',
          }}
        >
  
        <Box
          opacity={isOpen ? 1 : 0}
          transition="opacity 0.2s ease-in-out"
        >
          {/* Navigation Links */}
          <Stack
            spacing="20px"
            align="start"
            mt="5rem"
            ml="1rem"
          >
            <Link as={RouterLink} to="/nfts" {...linkStyle} onClick={onToggle}>NFTs</Link>
            <Link {...linkStyle} onClick={() => { 
    onToggle(); 
    window.location.href = 'https://angry-web3.gitbook.io/angry-web3-litepaper/'; 
}}>
    Litepaper
</Link>

            {/* <Link as={RouterLink} to="/Aboutus" {...linkStyle} onClick={onToggle}>About</Link>*/}
            <Button
                bg="#F84565"
                color="white"
                fontSize="16px"
                fontWeight="semibold"
                fontFamily="'Lexend Peta', sans-serif"
                borderRadius="16px"
                _hover={{ bg: "#f96d7e" }}
                _active={{ bg: "#f73855" }}
                sx={linkStyle}
                onClick={() => {
                  scrollToProducts();
                  onToggle();
                }}
                padding="10px 20px" // Increase padding (vertical horizontal)
                width="130px" // Adjust width as needed
                height="40px" // Adjust height as needed
              >
                Products
              </Button>
          </Stack>
        </Box>
        </Box>
    </Box>
    {/* NAV DONE*/}
    </Box> // Ensure this closing tag is present
  );
};

export default Navbar;
