import React from 'react';
import { Flex, Image, Text as ChakraText, useBreakpointValue, Box, Link, Text} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Link as RouterLink } from 'react-router-dom';

const ProductDetails = () => {
    const text = useBreakpointValue({ base: 'PRODUCTS', lg: 'Explore our products' });

    const boxWidth = useBreakpointValue({ base: "75%", sm: "65%", md: "380px", lg: "920px" });
    const boxHeight = useBreakpointValue({ base: "65px", sm: "70px", md: "75px", lg: "200px" });

    const boxWidth4 = useBreakpointValue({ base: "75%", sm: "65%", md: "380px", lg: "300px" });
    const boxHeight4 = useBreakpointValue({ base: "65px", sm: "70px", md: "75px", lg: "200px" });

    const boxWidth5 = useBreakpointValue({ base: "75%", sm: "65%", md: "380px", lg: "300px" });
    const boxHeight5 = useBreakpointValue({ base: "65px", sm: "70px", md: "75px", lg: "200px" });

    const fontSize = useBreakpointValue({ base: '16px', sm: "18px", md: '20px', lg: '32px' });
    const fontSize8 = useBreakpointValue({ base: '16px', sm: "18px", md: '20px', lg: '24px' });


    const MotionBox = motion(Box);
    const hoverTransition = { duration: 0.3 };
    const MotionText = motion(ChakraText);  

    const slideUpVariants = {
        hidden: { y: 50, opacity: 0 },
        visible: { y: 0, opacity: 1 }
      };

      const gradientStrokeStyle = {
        background: '#441E62',
        border: '0px solid transparent',
        borderRadius: '50px',
    };
    

    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.3,
    });

    return (
        <motion.div w="100%" pt={{ base: '25px', md: '25px' }} textAlign="center" ref={ref}>
            <Flex justify="center" align="center">
                <Image
                    src="/fire.png"
                    alt="Fire icon"
                    mr={{ base: "2", sm: "2", lg: "4" }}
                    ml={{ base: "-5", sm: "-7", lg: "0" }}
                    mb={3}
                    width={{ base: "30px", sm: "40px", md: "50px" }}
                    height={{ base: "40px", sm:"50px", md: "60px" }}
                />
                <Text
                    color="whiteAlpha.900"
                    fontSize={{ base: "24px", sm: "28px", md: "36px", lg: "42px" }}
                    fontWeight="black"
                    fontFamily="'Lexend Peta', sans-serif"
                >
                    {text}
                </Text>
            </Flex>

            {/* Flex container for the boxes */}
            <Flex
                direction={{ base: 'column', lg: 'row' }}
                wrap="wrap"
                justify="center"
                align="center"
                mt="20px"
            >
             {/* First Box */}
                <MotionBox
                    {...gradientStrokeStyle}
                    backgroundColor={"#461874"}
                    borderRadius="36px"
                    width={boxWidth}
                    height={boxHeight}
                    transition={{ duration: 0.8, delay: 0}}
                    initial="hidden"
                    animate={inView ? 'visible' : 'hidden'}
                    variants={slideUpVariants}
                    whileHover={{ scale: 1.01, transition: hoverTransition }}
                    position="relative"
                    overflow="hidden"
                    >
                    <Box display={{ base: "none", sm: "none", md: "none", lg: "block" }}>
                    <Box 
                      zIndex={1}
                      position="absolute"
                      top={{ base: "10%", sm: "15%", md: "20%", lg: "5%" }} // Adjust Y position
                      left={{ base: "10%", sm: "15%", md: "20%", lg: "0%" }} // Adjust X position
                    >
                      <Image 
                        zIndex={1}
                        src="../Coindrop.png" 
                        alt="NFT"
                        width={{ base: "50%", sm: "60%", md: "70%", lg: "30%" }} // Adjust sizes as needed
                        height="auto"
                      />
                    </Box>
                    <Box 
                      zIndex={1}
                      position="absolute"
                      top={{ base: "10%", sm: "15%", md: "20%", lg: "-10%" }} // Adjust Y position
                      left={{ base: "10%", sm: "15%", md: "20%", lg: "20%" }} // Adjust X position
                    >
                      <Image 
                        zIndex={1}
                        src="../Coindrop.png" 
                        alt="NFTproject"
                        width={{ base: "50%", sm: "60%", md: "70%", lg: "40%" }} // Adjust sizes as needed
                        height="auto"
                      />
                    </Box>
                    

                    <Box 
                      zIndex={1}
                      position="absolute"
                      top={{ base: "10%", sm: "15%", md: "20%", lg: "5%" }} // Adjust Y position
                      left={{ base: "10%", sm: "15%", md: "20%", lg: "70%" }} // Adjust X position
                    >
                      <Image 
                        zIndex={1}
                        src="../Coindrop.png" 
                        alt="Hedera nft"
                        width={{ base: "50%", sm: "60%", md: "70%", lg: "350%" }} // Adjust sizes as needed
                        height="auto"
                      />
                    </Box>
                    
                    <Box 
                      zIndex={1}
                      position="absolute"
                      top={{ base: "10%", sm: "15%", md: "20%", lg: "-5%" }} // Adjust Y position
                      left={{ base: "10%", sm: "15%", md: "20%", lg: "40%" }} // Adjust X position
                    >
                      <Image 
                        zIndex={1}
                        src="../Coindrop.png" 
                        alt="polygon nft"
                        width={{ base: "50%", sm: "60%", md: "70%", lg: "300%" }} // Adjust sizes as needed
                        height="auto"
                      />
                    </Box>

                    <Box 
                      zIndex={3}
                      position="absolute"
                      top={{ base: "10%", sm: "15%", md: "20%", lg: "-10%" }} // Adjust Y position
                      left={{ base: "10%", sm: "15%", md: "20%", lg: "55%" }} // Adjust X position
                      transform="rotate(-10deg)" // Rotate the image by 10 degrees
                      opacity={0.9} // Set opacity to 80%
                    >
                      <Image 
                        zIndex={3}
                        src="../vasj.png" 
                        alt="Cryptoproject"
                        width={{ base: "50%", sm: "60%", md: "70%", lg: "56%" }} // Adjust sizes
                        height="auto"
                      />
                    </Box>

                    <Box 
                      zIndex={3}
                      position="absolute"
                      top={{ base: "10%", sm: "15%", md: "20%", lg: "20%" }} // Adjust Y position
                      left={{ base: "10%", sm: "15%", md: "20%", lg: "85%" }} // Adjust X position
                      transform="rotate(20deg)" // Rotate the image by 10 degrees
                      opacity={1} // Set opacity to 80%
                     
                    >
                      <Image 
                        zIndex={3}
                        src="../coing.png" 
                        alt="1000x crypto"
                        width={{ base: "50%", sm: "60%", md: "60%", lg: "75%" }} // Adjust sizes
                        height="auto"
                        Shadow="0px 10px 50px rgba(0, 0, 0, 1)"
                      />
                    </Box>

                    <Box 
                      zIndex={3}
                      position="absolute"
                      top={{ base: "10%", sm: "15%", md: "20%", lg: "0%" }} // Adjust Y position
                      Right={{ base: "10%", sm: "15%", md: "20%", lg: "65%" }} // Adjust X position
                      transform="rotate(-20deg)" // Rotate the image by 10 degrees
                      opacity={1} // Set opacity to 80%
                    >
                      <Image 
                        zIndex={3}
                        src="../Roulletbar.png" 
                        alt="NFTs"
                        width={{ base: "50%", sm: "60%", md: "60%", lg: "30%" }} // Adjust sizes
                        height="auto"
                      />
                    </Box>
                    </Box>

                  <Link zIndex={5} href="https://angryroll.com//" isExternal _hover={{ textDecoration: 'none' }}>
                    
                    <Flex 
                        flexDirection="column" 
                        justifyContent="center" 
                        alignItems="center" 
                        width="100%" 
                        height="100%" 
                        zIndex={7}
                    >
                      
                      <MotionText
                            zIndex={7}
                            fontFamily="'Lexend mega', sans-serif"
                            fontWeight="bold"
                            fontSize={fontSize} 
                            color="whiteAlpha.900"
                            textAlign="center"
                            letterSpacing="-3px" // Reducing the letter spacing
                            wordSpacing="-1px" // Optional: Reducing the word spacing
                            lineHeight="1" // Optional: Tightening line height
                        >
                        Casino ➜
                        <Text fontSize={16}>Demo live</Text>
                      </MotionText>
                    </Flex>
                  </Link>
                  </MotionBox>
                {/* First Box Done*/}
                  <Box/>
                </Flex>
                {/* First row done*/}




                
                <Flex
                direction={{ base: 'column', lg: 'row' }}
                wrap="wrap"
                justify="center"
                align="center"
                mt="10px"
                gap={{ base: "15px", md: "10px" }}
                mb="1rem"
            >
                              {/* Third Box */}
                <MotionBox
                    {...gradientStrokeStyle}
                    backgroundColor={"#461874"}
                    borderRadius="36px"
                    width={boxWidth4}
                    height={boxHeight4}
                    transition={{ duration: 0.8, delay: 0.3}}
                    initial="hidden"
                    animate={inView ? 'visible' : 'hidden'}
                    variants={slideUpVariants}
                    whileHover={{ scale: 1.01, transition: hoverTransition }}
                    position="relative"
                    overflow="hidden"
                  >
                    <Box 
        
                      zIndex={1}
                      position="absolute"
                      top={{ base: "10%", sm: "15%", md: "20%", lg: "-10%" }} // Adjust Y position
                      left={{ base: "10%", sm: "15%", md: "20%", lg: "0%" }} // Adjust X position
                      transform="rotate(-3deg)" // Rotate the image by 10 degrees
                      opacity={0.6}
                    >
                      <Image 
                        display={{ base: "none", sm: "none", md: "none", lg: "block" }}
                        zIndex={1}
                        src="../Angrypass.png" 
                        alt="Angrypass"
                        width={{ base: "100%", sm: "100%", md: "100%", lg: "100%" }} // Adjust sizes
                        height="auto"
                      />
                    </Box>

                  <Link  zIndex={5} as={RouterLink} to="/Angrypass" textDecoration="none" _hover={{ textDecoration: 'none' }}>
                    <Flex 
                        flexDirection="column" 
                        justifyContent="center" 
                        alignItems="center" 
                        width="100%" 
                        height="100%" 
                        zIndex={7}
                    >
                      <MotionText
                            fontFamily="'Lexend mega', sans-serif"
                            fontWeight="bold"
                            fontSize={fontSize8} 
                            color="whiteAlpha.800"
                            textAlign="center"
                            ml='12px'
                            letterSpacing="-3px" // Reducing the letter spacing
                            wordSpacing="-1px" // Optional: Reducing the word spacing
                            lineHeight="1" // Optional: Tightening line height
                            zIndex={7}
                        >
                        SR Holders ➜
                        <Text fontSize='16px'>ANGRY PASS</Text>
                      </MotionText>
                      
                    </Flex>
                    </Link>
                  </MotionBox>


                {/* Fourth Box */}
                <MotionBox
                    {...gradientStrokeStyle}
                    backgroundColor={"#461874"}
                    borderRadius="36px"
                    width={boxWidth4}
                    height={boxHeight4}
                    transition={{ duration: 0.8, delay: 0.3}}
                    initial="hidden"
                    animate={inView ? 'visible' : 'hidden'}
                    variants={slideUpVariants}
                    whileHover={{ scale: 1.01, transition: hoverTransition }}
                    position="relative"
                  >
                  <Box/>  {/* Image here */}

                    
                    <Flex 
                        flexDirection="column" 
                        justifyContent="center" 
                        alignItems="center" 
                        width="100%" 
                        height="100%" 
                        zIndex={2}
                    >
                      <MotionText
                            fontFamily="'Lexend mega', sans-serif"
                            fontWeight="bold"
                            fontSize={fontSize8} 
                            color="whiteAlpha.800"
                            textAlign="center"
                            ml='12px'
                            letterSpacing="-3px" // Reducing the letter spacing
                            wordSpacing="-1px" // Optional: Reducing the word spacing
                            lineHeight="1" // Optional: Tightening line height
                        >
                        Defi ➜
                        <Text fontSize='16px'>Coming soon</Text>
                      </MotionText>
                    </Flex>
                    
                  </MotionBox>

                {/* Fifth Box */}
                <MotionBox
                    {...gradientStrokeStyle}
                    backgroundColor={"#461874"}
                    borderRadius="36px"
                    width={boxWidth5}
                    height={boxHeight5}
                    transition={{ duration: 0.8, delay: 0.4}}
                    initial="hidden"
                    animate={inView ? 'visible' : 'hidden'}
                    variants={slideUpVariants}
                    whileHover={{ scale: 1.01, transition: hoverTransition }}
                    position="relative"
                    overflow="hidden"
                  >
                    <Box 
                      zIndex={1}
                      position="absolute"
                      top={{ base: "10%", sm: "15%", md: "20%", lg: "-95%" }} // Adjust Y position
                      left={{ base: "10%", sm: "15%", md: "20%", lg: "25%" }} // Adjust X position
                      transform="rotate(0deg)" // Rotate the image by 10 degrees
                      opacity={0.6}
                    >
                      <Image 
                        display={{ base: "none", sm: "none", md: "none", lg: "block" }}
                        zIndex={1}
                        src="../INVESTscreen.png" 
                        alt="NFTgems"
                        width={{ base: "100%", sm: "100%", md: "100%", lg: "100%" }} // Adjust sizes
                        height="auto"
                      />
                    </Box>

                  <Link  zIndex={5} as={RouterLink} to="/Investor" textDecoration="none" _hover={{ textDecoration: 'none' }}>
                    <Flex 
                        flexDirection="column" 
                        justifyContent="center" 
                        alignItems="center" 
                        width="100%" 
                        height="100%" 
                        zIndex={7}
                    >
                      <MotionText
                            zIndex={7}
                            fontFamily="'Lexend mega', sans-serif"
                            fontWeight="bold"
                            fontSize={fontSize8} 
                            color="whiteAlpha.800"
                            textAlign="center"
                            letterSpacing="-3px" // Reducing the letter spacing
                            wordSpacing="-1px" // Optional: Reducing the word spacing
                            lineHeight="1" // Optional: Tightening line height
                        >
                        Investors ➜
                      </MotionText>
                    </Flex>
                  </Link>
                  </MotionBox>
                </Flex>
        </motion.div>
    );
};

export default ProductDetails;
