import React, { Suspense, useState, useEffect, useRef } from 'react';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { OrbitControls, useGLTF } from '@react-three/drei';

function Model() {
  const { scene } = useGLTF('/Token.glb');
  return <primitive object={scene} />;
}

function CameraLight() {
  const pointLightRef = useRef();
  const { camera } = useThree();

  useFrame(() => {
    if (pointLightRef.current) {
      pointLightRef.current.position.copy(camera.position);
    }
  });

  return <pointLight ref={pointLightRef} position={[0, 0, 0]} />;
}

const ThreeDModel = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 450);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 450);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div style={{ height: isMobile ? '350px' : '550px', width: '100%' }}>
      <Canvas camera={{ position: [0, 0, 5], fov: 40 }}>
        <ambientLight intensity={0.5} />
        <directionalLight intensity={1} position={[10, 10, 5]} />
        <Suspense fallback={null}>
          <Model />
          <CameraLight />
        </Suspense>
        <OrbitControls enableZoom={false} enablePan={false} />
      </Canvas>
    </div>
  );
};
export default ThreeDModel;
