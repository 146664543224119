import React from 'react';
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  useBreakpointValue,
  Text,
  Image,
  Flex,
  Progress
} from '@chakra-ui/react';


const Card1 = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const buttonStyles = useBreakpointValue({
    base: {
      width: '150px',
      height: '50px',
      padding: '1px',
      borderRadius: '30px',
      overflow: 'hidden',
      bgGradient: 'linear(to right, #9B9292 0%, rgba(255, 255, 255, 0.29) 50%, #9B9292 100%)',
    },
    sm: {
      width: '150px',
      height: '50px',
      padding: '1px',
      borderRadius: '30px',
      overflow: 'hidden',
      bgGradient: 'linear(to right, #9B9292 0%, rgba(255, 255, 255, 0.29) 50%, #9B9292 100%)',
    },
  });

  const svgStyles = useBreakpointValue({
    base: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '30px'
    },
    sm: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '30px'
    },
  });

  const textStyles = useBreakpointValue({
    base: { color: 'rgba(255, 255, 255, 0.8)', fontSize: '14px' },
    sm: { color: 'rgba(255, 255, 255, 0.8)', fontSize: '14px' },
  });

    {/* SentX */}
  const maxSupply1 = 1000;
  const remaining1 = 1000;
  const progressValue1 = ((remaining1) / maxSupply1) * 100;

  {/* TierBot */}
  const maxSupply2 = 1000;
  const remaining2 = 1000;
  const progressValue2 = ((remaining2) / maxSupply2) * 100;

  {/* Zues */}
  const maxSupply3 = 1000;
  const remaining3 = 1000;
  const progressValue3 = ((remaining3) / maxSupply3) * 100;

  {/* OpenSea */}
  const maxSupply4 = 1000;
  const remaining4 = 1000;
  const progressValue4 = ((remaining4) / maxSupply4) * 100;

  {/* OKX */}
  const maxSupply5 = 1000;
  const remaining5 = 1000;
  const progressValue5 = ((remaining5) / maxSupply5) * 100;



  return (
    <>
      <Button 
        sx={{
          ...buttonStyles,
          _hover: { transform: 'scale(1.05)' }
        }}
        onClick={onOpen}
      >
        <Box style={svgStyles} bg="#1C0D28" overflow="hidden">
          <Text style={textStyles}>
            MINT NOW
          </Text>
        </Box>
      </Button>

      {/* Popup Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent 
          width={{ base: "90%", md: "80%", lg: "70%" }}  // Custom width responsive
          maxWidth="500px"  // Maximum width limit
          height="600px"  // Custom height
          bgColor="#1C0D28"
          borderRadius="15px"
        >
          <ModalHeader mt="3rem" fontSize="20px" textColor="whiteAlpha.900" textAlign="center">Avilable launchpad</ModalHeader>
          <ModalCloseButton p="1rem" size="20px" color="grey" />

          <ModalBody p="3rem" direction="row">

            <Text textColor="whiteAlpha.900" pb="3rem" px="3rem">Hedera network : </Text>
            {/* Content and buttons inside the modal */}
            <Flex direction="row" justify="space-around" align="center"> {/* Flex container with row direction */}

              <Box direction="column">
                <a href="https://external-link-2.com" target="_blank" rel="noopener noreferrer">
                  <Image w="80px" h="80px" src="../launchpad logos/sentlogo.webp"/>
                  <Text ml="0.7rem" textColor="whiteAlpha.900">Sentx</Text>
                </a>
                <Progress
                  value={progressValue1}
                  colorScheme="green"
                  hasStripe 
                  sx={{
                    borderRadius: 'full', // For rounded corners
                    height: '8px', // Adjust size as needed
                    mb: 2}}/>
                <Text textColor="whiteAlpha.900" fontSize="12px">1000/1000</Text>
              </Box>

              <Box direction="column">
                <a href="https://external-link-2.com" target="_blank" rel="noopener noreferrer">
                  <Image ml="1rem" w="75px" h="80px" src="../launchpad logos/nfttier.svg"/>
                  <Text ml="0.8rem" textColor="whiteAlpha.900">TierBot</Text>
                </a>
                <Progress
                  ml="0.8rem"
                  width="80px"
                  value={progressValue2}
                  colorScheme="green"
                  hasStripe 
                  sx={{
                    borderRadius: 'full', // For rounded corners
                    height: '8px', // Adjust size as needed
                    mb: 2}}/>
                <Text ml="0.8rem" textColor="whiteAlpha.900" fontSize="12px">1000/1000</Text>
              </Box>

              <Box direction="column">
                <a href="https://external-link-2.com" target="_blank" rel="noopener noreferrer">
                  <Image w="90px" h="80px" src="../launchpad logos/zues.png"/>
                  <Text ml="2rem" textColor="whiteAlpha.900">Zues</Text>
                </a>
                <Progress
                  ml="0.8rem"
                  width="80px"
                  value={progressValue3}
                  colorScheme="green"
                  hasStripe 
                  sx={{
                    borderRadius: 'full', // For rounded corners
                    height: '8px', // Adjust size as needed
                    mb: 2}}/>
                <Text ml="0.8rem" textColor="whiteAlpha.900" fontSize="12px">1000/1000</Text>
              </Box>

            </Flex>
            <Text textColor="whiteAlpha.900" pb="3rem" pt="4rem" px="3rem">Polygon chain : </Text>
            <Flex direction="row" justify="space-around" align="center"> {/* Flex container with row direction */}

              <Box direction="column">
                <a href="https://external-link-2.com" target="_blank" rel="noopener noreferrer">
                  <Image ml="1rem" w="80px" h="80px" src="../launchpad logos/opensea-logo.svg"/>
                  <Text textAlign="center" textColor="whiteAlpha.900">OpenSea</Text>
                </a>
                <Progress
                  value={progressValue4}
                  colorScheme="green"
                  hasStripe 
                  sx={{
                    borderRadius: 'full', // For rounded corners
                    height: '8px', // Adjust size as needed
                    mb: 2}}/>
                <Text textAlign="center" textColor="whiteAlpha.900" fontSize="12px">1000/1000</Text>
              </Box>

              <Box direction="column">
                <a href="https://external-link-2.com" target="_blank" rel="noopener noreferrer">
                  <Image w="120px" h="80px" src="../launchpad logos/okx.png"/>
                  <Text textAlign="center" textColor="whiteAlpha.900">OKX</Text>
                </a>
                <Progress
                  value={progressValue5}
                  colorScheme="green"
                  hasStripe 
                  sx={{
                    borderRadius: 'full', // For rounded corners
                    height: '8px', // Adjust size as needed
                    mb: 2}}/>
                <Text textAlign="center" textColor="whiteAlpha.900" fontSize="12px">1000/1000</Text>
              </Box>

              </Flex>
          </ModalBody>
         
        </ModalContent>
      </Modal>
    </>
  );
};

export default Card1;
