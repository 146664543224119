// src/theme.js

import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  fonts: {
    heading: `'Lexend Peta', sans-serif`,
    body: `'Lexend Mega', sans-serif`,
    body1: `'Lexend', sans-serif`,
  },
  fontWeights: {
    medium: 500,
    semibold: 600, 
    bold: 700,
    extraBold: 800, 
    black: 900, 
  },
});

export default theme;
