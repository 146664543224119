import React, { useState} from 'react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Box, Text, useBreakpointValue, Flex, SimpleGrid, VStack} from '@chakra-ui/react';
import './Swiper.css';
import Card1 from './CardStyle1';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const cardContents = [
    {
    title: "Story",
    description: "This exclusive 3D Angry Pass from ANGRY Web3 presents a unique opportunity for you to own shares in our business, including access to a private pool of 25% of the platform  fees and revenue.\n\nThe primary goal of this collection is to raise funds for our casino, recognizing that substantial capital is essential for robust operation.\n\n Our aim is to fortify the business's financial flow and ensure long-term stability. Thus, we are offering these NFTs as a fundraising avenue, opening doors for us to uncover more opportunities and facilitate growth. \n\nWe encourage you to check out our ecosystem chart and discover the perks of this collection.",
    },
    {
    title: "Perks",
    description: "• Monthly & Annually Rewards.\n• Join the Angry pass pool.\n• Lifetime whitelist guarantee.\n\n\nDetails\n• Private Mint: NAN\n• Public Mint: 13/02/24\n• Supply: 5,000 Pieces.\n• Type: Tier SR.\n• Mint price: 188 HBAR, 18 Matic.\n• Chain: Hedera network & Polygon chain.",
    },
];  

const Card = ({ title, description, maxCharacterLimit }) => {
    const [isExpanded, setIsExpanded] = useState(false); // State to track expansion
    const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility

    // Determine the size of the card based on the breakpoint
    const cardSize = useBreakpointValue({ base: 'base', sm: 'sm', md: 'md', lg: 'lg' });

    // Styles for different sizes
    const sizeStyles = {
        base: { width: '90vw', height: 'auto' },
        sm: { width: '480px', height: 'auto' },
        md: { width: '700px', height: 'auto' },
        lg: { width: '45vw', height: 'auto', maxWidth: '750px' },
    };

    // Responsive font sizes
    const titleFontSize = { base: "17px", sm: "20px", md: "22px", lg: "24px" };
    const descriptionFontSize = { base: "14px", sm: "16px", md: "18px", lg: "18px" };
    const customFontSize = { base: "13px", sm: "14px", md: "16px", lg: "16px" };

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const toggleExpansion = () => {
        setIsExpanded(!isExpanded);
    };

    const renderReadMore = () => {
        if (description.length <= maxCharacterLimit) return null;

        let readMoreStyle = {};
        if (cardSize === 'lg') {
            readMoreStyle = {
                textAlign: 'left',
                marginLeft: '0px',
            };
        }

        return (
            <Text
                color="blue.400"
                fontSize={customFontSize[cardSize]}
                cursor="pointer"
                onClick={toggleExpansion}
                style={readMoreStyle}
            >
                {isExpanded ? "Show less" : "Read more"}
            </Text>
        );
    };

    const descriptionContent = (isExpanded ? description : `${description.substring(0, maxCharacterLimit)}...`).split('\n').map((line, index) => {
        if (line.includes("ecosystem chart")) {
            const parts = line.split("ecosystem chart");
            return (
                <Text key={index} color="whiteAlpha.800" fontSize={descriptionFontSize[cardSize]} style={{ textIndent: '40px' }}>
                    {parts[0]}
                    <Text as="span" color="blue.400" cursor="pointer" onClick={openModal}>
                        Ecosystem 
                    </Text>
                    {" chart" + parts[1]}
                </Text>
            );
        } else {
            // Your existing logic for rendering non-ecosystem chart lines
            return (
                <Text 
                    key={index} 
                    color="whiteAlpha.800" 
                    fontSize={descriptionFontSize[cardSize]} 
                    style={{ textIndent: '40px' }}>
                    {line}
                </Text>
            );
        }
    });
    

    return (
        <>
            <Box
                id="angry-pass-section"
                bg="transparent"
                color="white"
                pl="2rem"
                pr="2rem"
                borderRadius="md"
                boxShadow="md"
                {...sizeStyles[cardSize]}
            >
                <Text color="whiteAlpha.800" fontSize={titleFontSize[cardSize]} textAlign="center" fontWeight="bold" mt={3}>
                    {title}
                </Text>
                <VStack align="start" spacing={2} pt="1rem">
                    {descriptionContent}
                    {renderReadMore()}
                </VStack>
            </Box>

            {/* Modal for displaying the image */}
            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: '95%', // Set width of the popup (increased from 80%)
                        maxWidth: '700px', // Max width for larger screens (increased)
                        borderRadius: '10px', // Rounded corners
                        backgroundColor: '#301644',
                    }
                }}
            >
                <img src="../Ecomap.png" alt="Ecosystem Chart" />
                <button onClick={closeModal} style={{ marginTop: '10px', color: 'white' }}>Close</button>
            </Modal>
        </>
    );
};


function SwiperComponentSR() {

    const TEXT_STYLES = {
        color: "whiteAlpha.900",
        fontSize: { base: "24px", sm: "28px", md: "32px", lg: "36px" },
        fontWeight: "black",
        fontFamily: "'Lexend Peta', sans-serif"
    };

    const TEXT_STYLES2 = {
        color: "whiteAlpha.700",
        fontSize: { base: "18px", sm: "20px", md: "22px", lg: "24px" },
        fontWeight: "black",
        fontFamily: "'Lexend Peta', sans-serif"
    };

  return (
    <Box
        w="100vw"
        minW="100vw"
        minH="100vh"
        bg="#1C0D28"
        bgPos="center"
        bgSize="cover"
        bgRepeat="no-repeat"
        position="relative"
        overflowX="hidden"
        overflowY="hidden"
        css={{
            '@supports not (-webkit-touch-callout: none)': {
                width: '100vw',
            }
        }}
    >
    <Box mt={{base:"8rem", sm:"8rem",md:"8rem", lg:"10rem"}}>
      <Text {...TEXT_STYLES} textAlign="center" mt="1rem" id="angry-pass-section" >SR : Angry Pass </Text>
      <Text {...TEXT_STYLES2} textAlign="center" id="angry-pass-section" >Casino Fundraising Series </Text>
      <Box 
        display="flex" 
        justifyContent="center" 
        alignItems="center" 
        pt="3rem" 
        pb="3rem"
        width={{base:"100vw", sm:"500px" }}
        height="auto"
        overflow="hidden"
        mx="auto"
    >
        <video
            autoPlay
            loop
            playsInline
            muted 
            style={{ 
                maxWidth: "80%", 
                height: "auto", 
                borderRadius: "8px",
                boxShadow: "0 0 25px #8E4585"
            }}
        >
            <source src="../NFTBG/Angry_Pass_Video.mp4" type="video/mp4" />
            Your browser does not support the video tag.
        </video>
    </Box>

            <Box display="flex" justifyContent="center">
                <Card1/>
            </Box>
        </Box>

        <Flex justifyContent="center" alignItems="center" pt={{base: "2rem", sm:"4rem"}}>
            <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={10} p={0}>
                {cardContents.map((card, index) => (
                    <Card
                        key={index}
                        title={card.title}
                        description={card.description}
                        imageUrl={card.imageUrl}
                        maxCharacterLimit={card.title.toLowerCase() === "perks" ? 70 : 135} // Example: 200 for Perks, 135 for others
                    />
                ))}
            </SimpleGrid>
        </Flex>
    </Box>
    );
}

export default SwiperComponentSR;