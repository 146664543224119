import React, { useState, useEffect, useRef } from 'react';
import { Box, Flex, Image, Text, Spinner} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { IoIosReturnLeft } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import OrientationWarning from './Rotate';
import { useMediaQuery } from '@chakra-ui/react';

const Aboutus= () => {
    const videoRef = useRef(null);

    useEffect(() => {
      const handleVideoEnd = () => {
        // Delay for 3 seconds before restarting the video
        setTimeout(() => {
          // Make sure the video is not null before attempting to play
          if (videoRef.current) {
            videoRef.current.play();
          }
        }, 3000);
      };
  
      // Add event listener for the "ended" event
      if (videoRef.current) {
        videoRef.current.addEventListener('ended', handleVideoEnd);
      }
  
      // Cleanup the event listener when the component is unmounted
      return () => {
        if (videoRef.current) {
          videoRef.current.removeEventListener('ended', handleVideoEnd);
        }
      };
    }, []); // Empty dependency array ensures that this effect runs only once
  
    const navigate = useNavigate();
    const [isSmallScreen] = useMediaQuery("(max-width: 768px)");

    const [isLoading, setIsLoading] = useState(true);
    // Framer Motion variants for fade-out effect
    const fadeOut = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 },
        exit: { opacity: 0 }
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 1500);
        return () => clearTimeout(timer);
    }, []);

    const TEXT_STYLES = {
        color: "whiteAlpha.900",
        fontSize: { base: "26px", sm: "34px", md: "36px", lg: "42px" },
        fontWeight: "black",
        fontFamily: "'Lexend Peta', sans-serif"
    };

    const handleReturnClick = () => navigate('/');

    const linkStyle = {
        p: "2",
        borderRadius: "md",
        _hover: {
            bg: "#461874",
            borderRadius: "md",
        },
    };

    if (isLoading) {
        return (
            <motion.div
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={fadeOut}
                transition={{ duration: 0.5 }}
            >
                <Box
                    w="100vw"
                    h="100vh"
                    bg="#1C0D28"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Text {...TEXT_STYLES}>Long story...</Text>
                    <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="purple.400" size="xl" mt="4"/>
                </Box>
            </motion.div>
        );
    }
    
    return (
        <Box
            w="100vw"
            h="100vh"
            bg="#1C0D28"
            bgPos="center"
            bgSize="cover"
            bgRepeat="no-repeat"
            position="relative"
            overflowX="hidden"
            overflowY="hidden"
            css={{
                '@supports not (-webkit-touch-callout: none)': {
                    width: '100vw'
                }
            }}
        >
            {isSmallScreen && <OrientationWarning />}
            {/* Navigation */}
            <Flex
                as="nav"
                align="center"
                justify="space-between"
                wrap="wrap"
                padding="1.5rem"
                bg="#1C0D28"
                color="white"
                position="absolute"
                width="full"
                zIndex={2}
            >
                <Box position="relative" ml={{ base: '-0.5rem', sm: "1rem" ,md: '1rem', lg: '3rem' }}  mt={{base: '0.3rem', sm: '0rem'}}>
                    <Image
                        src="/icon.png"
                        w={{ base: '45px', sm: '50px' }}
                        h={{ base: '47px', sm: '50px' }}
                    />
                    <Box
                        position="absolute"
                        top="0"
                        left="0"
                        right="0"
                        bottom="0"
                        cursor="pointer"
                        onClick={handleReturnClick}
                    />
                </Box>
                <Box {...linkStyle} onClick={handleReturnClick} mr={{ base: '-0.5rem', sm: '0.3rem', md: '0.5rem', lg: '2.7rem' }}>
                    <IoIosReturnLeft size="36px" />
                </Box>
            </Flex>
            
                <Box textAlign="center" pt="10rem" align-items="center" maxW="850px" mx="auto"> 
                    <motion.div>
                    <video
          ref={videoRef}
          src="/webr1.mp4"
          muted
          height="80%"
          autoPlay
          loop
          style={{ objectFit: 'cover', width: '100%' }}
        />
                    </motion.div>
                </Box>

            <Footer />
        </Box>
    );
};

export default Aboutus;
