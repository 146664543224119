import React, { useState, useEffect } from 'react';
import { Box, Flex, Image, Text, Spinner} from '@chakra-ui/react';
import { IoIosReturnLeft } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import OrientationWarning from './Rotate';
import {useMediaQuery} from '@chakra-ui/react';
import SwiperComponentR from './SwiperR';
import SwiperComponentS from './SwiperS';
import SwiperComponentSR from './SwiperSR';
import SwiperComponentSSR from './SwiperSSR';
import { motion } from 'framer-motion';
import ButtoninNFT from './ButtoninNFT'

const NFTs = () => {

    const TEXT_STYLES = {
        color: "whiteAlpha.900",
        fontSize: { base: "24px", sm: "28px", md: "32px", lg: "36px" },
        fontWeight: "black",
        fontFamily: "'Lexend Peta', sans-serif"
    };

    const [isSmallScreen] = useMediaQuery("(max-width: 768px)");

    const navigate = useNavigate();

    const handleReturnClick = () => {
        navigate('/');
    };

    const linkStyle = {
        p: "2",
        borderRadius: "md",
        _hover: {
            bg: "#461874",
            borderRadius: "md",
        },
    };

    const [isLoading, setIsLoading] = useState(true);
    // Framer Motion variants for fade-out effect
    const fadeOut = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 },
        exit: { opacity: 0 }
    };
  
    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 1000);
        return () => clearTimeout(timer);
    }, []);
  
    const TEXT_STYLES7 = {
        color: "whiteAlpha.900",
        fontSize: { base: "30px", sm: "34px", md: "36px", lg: "42px" },
        fontWeight: "black",
        fontFamily: "'Lexend Peta', sans-serif"
    };
  
    if (isLoading) {
      return (
          <motion.div
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={fadeOut}
              transition={{ duration: 0.5 }}
          >
              <Box
                  w="100vw"
                  h="100vh"
                  bg="#1C0D28"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
              >
                  <Text {...TEXT_STYLES7}>REAL 🎨</Text>
                  <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="purple.400" size="xl" mt="4"/>
              </Box>
          </motion.div>
      );
  }
  

    return (
        <Box
            w="100vw"
            bg="#1C0D28"
            bgPos="center"
            bgSize="cover"
            bgRepeat="no-repeat"
            position="relative"
            overflowX="hidden"
            overflowY="hidden"
            css={{
                '@supports not (-webkit-touch-callout: none)': {
                    width: '100vw'
                }
            }}
        >
            {isSmallScreen && <OrientationWarning />}
            {/* Navigation */}
            <Flex
                as="nav"
                align="center"
                justify="space-between"
                wrap="wrap"
                padding="1.5rem"
                bg="#1C0D28"
                color="white"
                position="absolute"
                width="full"
                zIndex={2}
            >
                <Box position="relative" ml={{ base: '0rem', sm: "1rem" ,md: '1rem', lg: '2.5rem' }}  mt={{base: '0.3rem', sm: '0rem'}}>
                    <Image
                        src="/icon.png"
                        w={{ base: '45px', sm: '50px' }}
                        h={{ base: '45px', sm: '50px' }}
                    />
                    <Box
                        position="absolute"
                        top="0"
                        left="0"
                        right="0"
                        bottom="0"
                        cursor="pointer"
                        onClick={handleReturnClick}
                    />
                </Box>
                <Box {...linkStyle} onClick={handleReturnClick} mr={{ base: '-0.5rem', sm: '0.3rem', md: '0.5rem', lg: '2.7rem' }}>
                    <IoIosReturnLeft size="36px" />
                </Box>
            </Flex>
            
                <Box textAlign="center" pt="10rem"> {/* Added textAlign and pt for spacing and alignment */}
                    <Text {...TEXT_STYLES}>
                        NFT COLLECTIONS
                    </Text>
                </Box>

                <Flex
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    pt="4rem"
                    gap="2rem"
                    ml={{ base: "-18rem", sm: "-30rem", md: "-33rem", lg: "-36rem" }}
                    position="relative"
                    >
                    <Box position="relative">
                      <ButtoninNFT buttonName="SSR"/>
                        <a href="#web3-heiress-section">
                        <Text color="whiteAlpha.800" position="absolute" right={{base:"-180px",sm:"-200px"}} top="50%" transform="translateY(-50%)">TBA</Text>
                        </a>
                    </Box>
                    <Box position="relative">
                        <ButtoninNFT buttonName="SR"/>
                        <a href="#angry-pass-section">
                        <Text textShadow="0 0 10px #8E4585, 0 0 20px #8E4585, 0 0 30px #8E4585"  color="whiteAlpha.800" position="absolute" right={{base:"-180px",sm:"-200px"}} top="50%" transform="translateY(-50%)">Angry Pass</Text>
                        </a>
                    </Box>
                    <Box position="relative">
                        <ButtoninNFT buttonName="S"/>
                        <a href="#3d-angry-bot-section">
                        <Text color="whiteAlpha.800" position="absolute" right={{base:"-180px",sm:"-200px"}} top="50%" transform="translateY(-50%)">TBA</Text>
                        </a>
                    </Box>
                    <Box position="relative">
                        <ButtoninNFT buttonName="R"/>
                        <a href="#angry-lizard-section">
                        <Text color="whiteAlpha.800" position="absolute" right={{base:"-180px",sm:"-200px"}} top="50%" transform="translateY(-50%)">TBA</Text>
                        </a>
                    </Box>
                    <Box position="relative">
                    <ButtoninNFT buttonName="C"/>
                        <a href="#non">
                        <Text color="whiteAlpha.800" position="absolute" right={{base:"-180px",sm:"-200px"}} top="50%" transform="translateY(-50%)">TBA</Text>
                        </a>
                    </Box>
                </Flex>

            <Box pt="0rem" display="flex" alignItems="center" justifyContent="center" height="100%">
                <SwiperComponentSR/>
                
            </Box>

            <Footer />
        </Box>
    );
};

export default NFTs;
