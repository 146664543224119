import React from 'react';
import { Box } from '@chakra-ui/react';
import StatsSection from './Stats';
import ProductDetails from './ProductDetails';

const Products = () => {
  return (
    <Box
        id="Products"
        w="100vw"
        bg="#1C0D28"
        bgPos="center"
        bgSize="cover"
        bgRepeat="no-repeat"
        position="relative"
        overflowX="hidden"
        overflowY="hidden" 
        css={{
          '@supports not (-webkit-touch-callout: none)': {
            width: '100vw',
          }
        }}
      >
      <StatsSection/>
      <ProductDetails/>
    </Box>
  );
};

export default Products;
