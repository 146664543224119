import React from 'react';
import { Flex, Text, Box } from '@chakra-ui/react';

const StatsSection = () => {
  return (
    <Flex
      direction="row"
      justify="space-around"
      align="center"
      w="100vw"
      h="auto"
      bg="#1C0D28" // Assuming a dark background as in the screenshot
      p={4}
      color="whiteAlpha.800"
      pt={{base: "1.5rem", sm:"1rem", md:"2rem", lg:"1.5rem"}}  pb={{base:"3rem", sm:"4rem"}}
    >
      <Box textAlign="center">
        <Text fontSize={{ base: '16px', sm: "20px", md: '26px', lg:'34px' }} fontWeight="bold" fontFamily="'Lexend Peta', sans-serif">
          $10K+
        </Text>
        <Text fontSize={{ base: '10px', sm: "12px", md: '18px', lg:'22px' }} fontWeight="bold" fontFamily="'Lexend Peta', sans-serif" color="#858080" letterSpacing="-1.5px">
          Total volume
        </Text>
      </Box>

      <Box textAlign="center">
        <Text fontSize={{ base: '16px', sm: "20px", md: '26px', lg:'34px' }} fontWeight="bold" fontFamily="'Lexend Peta', sans-serif">
          3K+
        </Text>
        <Text fontSize={{ base: '10px', sm: "12px", md: '18px', lg:'22px' }} fontWeight="bold" fontFamily="'Lexend Peta', sans-serif" color="#858080" letterSpacing="-1.5px">
          Total traffic
        </Text>
      </Box>

      <Box textAlign="center">
         <Text fontSize={{ base: '16px', sm: "20px", md: '26px', lg:'34px' }} fontWeight="bold" fontFamily="'Lexend Peta', sans-serif">
          1K+
        </Text>
        <Text fontSize={{ base: '10px', sm: "12px", md: '18px', lg:'22px' }} fontWeight="bold" fontFamily="'Lexend Peta', sans-serif" color="#858080" letterSpacing="-1.5px">
          Total users
        </Text>
      </Box>

      <Box textAlign="center">
        <Text fontSize={{ base: '16px', sm: "20px", md: '26px', lg:'34px' }} fontWeight="bold" fontFamily="'Lexend Peta', sans-serif">
          1Y
        </Text>
        <Text fontSize={{ base: '10px', sm: "12px", md: '18px', lg:'22px' }} fontWeight="bold" fontFamily="'Lexend Peta', sans-serif" color="#858080" letterSpacing="-1.5px">
          Project age
        </Text>
      </Box>
    </Flex>
  );
};

export default StatsSection;
