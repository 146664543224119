import { Box, Image, Text, Button, Stack, Flex } from "@chakra-ui/react";

function BuyButton() {

    const handleClick = () => {
        window.location.href = 'https://next.waveapps.com/checkouts/d00157aa27254e8681d16e81fe4a9412';
      };

  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      bg="white"
      p="4"
      boxShadow="md"
      width={{base:"280px",sm:"330px"}}
      borderRadius="16px"
    >
      <Box p="4">
        <Image
          src="../Roadmap1.png"
          alt="Angry Web3 platform share"
          width="120px"
        />
      </Box>

      <Text fontSize="14" fontWeight="500" textColor="grey" textAlign="center">
        Angry Web3 platform share
      </Text>

      <Text fontSize="28" fontWeight="600" color="default">
        US$ 500
      </Text>

      <Text fontSize="12" fontWeight="600" color="default">
       ≈CAD$ 650
      </Text>

      <Button
        colorScheme="red"
        variant="solid"
        size="xl"
        my="8"
        h="50px"
        w={{base:"250px",sm:"300"}}
        borderRadius="40px"
        onClick={handleClick} 
      >
        BUY NOW : 3% LEFT
      </Button>

      <Box textAlign="center">
        <Text mb="2" textColor="grey" fontSize="10px">Supported payment methods:</Text>
        <Stack direction="row" spacing="4" align="center" justify="center">
          <Image src="../amex.svg" alt="amex" boxSize="40px" objectFit="cover" />
          <Image src="../master.svg" alt="mastercard" boxSize="40px" objectFit="cover" />
          <Image src="../visa.svg" alt="visa" boxSize="40px" objectFit="cover" />
        </Stack>
      </Box>
    </Flex>
  );
}

export default BuyButton;
