import { keyframes } from '@emotion/react';
import React, { useState, useEffect } from 'react';
import { Flex, Box, Image, Text } from '@chakra-ui/react';

// Create a keyframe animation
const rotateAnimation = keyframes`
  0% { transform: rotate(0); }
  50% { transform: rotate(-180deg); }
  100% { transform: rotate(-360deg); }
`;

const OrientationWarning = () => {
  // States for screen orientation and touch capability
  const [isLandscape, setIsLandscape] = useState(window.innerWidth > window.innerHeight);
  const [hasTouch, setHasTouch] = useState(false);

  useEffect(() => {
    // Function to check for touch capability
    const checkTouch = () => {
      setHasTouch('ontouchstart' in window || navigator.maxTouchPoints > 0);
    };

    // Function to check for landscape orientation
    const checkOrientation = () => {
      setIsLandscape(window.innerWidth > window.innerHeight);
    };

    // Set initial state
    checkTouch();
    checkOrientation();

    // Event listeners for changes in orientation or window size
    window.addEventListener('resize', checkOrientation);
    window.addEventListener('orientationchange', checkOrientation);

    // Cleanup event listeners
    return () => {
      window.removeEventListener('resize', checkOrientation);
      window.removeEventListener('orientationchange', checkOrientation);
    };
  }, []);

  // Only show the warning if the device is likely a touch device and is in landscape
  if (!hasTouch || !isLandscape) {
    return null;
  }

  return (
    <Flex
      position="fixed"
      top="0"
      left="0"
      right="0"
      bottom="0"
      width="100vw"
      height="100vh"
      justifyContent="center"
      alignItems="center"
      backgroundColor="white" // Set the background to white
      zIndex="9999"
      direction="column" // Stack the children vertically
    >
      {/* Rotating arrow - replace with your arrow icon/image */}
      <Box
        as="span"
        css={{
          animation: `${rotateAnimation} 2s ease-in-out infinite`,
        }}
      >
        {/* Replace this with your actual arrow icon/image */}
        <Image w="70px"  h="70px" src="/Rotate.png" alt="Rotate Device" />
      </Box>
      <Text color="black" fontSize="24px" mt="4">
        Please rotate your device back to portrait mode.
      </Text>
    </Flex>
  );
};

export default OrientationWarning;