import React, { useState } from 'react';
import { Box, Text, Button, useBreakpointValue, Link } from '@chakra-ui/react';
import { Checkmark } from 'react-checkmark';
import Modal from 'react-modal';

const Card5 = () => {
  const buttonStyles = useBreakpointValue({
    base: {
      width: '280px',
      height: '480px',
      padding: '1px',
      borderRadius: '8px',
      overflow: 'hidden',
      bgGradient: 'linear(to right, #9B9292 0%, rgba(255, 255, 255, 0.29) 50%, #9B9292 100%)',
    },
    sm: {
      width: '330px',
      height: '490px',
      padding: '1px',
      borderRadius: '8px',
      overflow: 'hidden',
      bgGradient: 'linear(to right, #9B9292 0%, rgba(255, 255, 255, 0.29) 50%, #9B9292 100%)',
    },
  });

  const svgStyles = useBreakpointValue({
    base: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      borderRadius: '8px'
    },
    sm: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      borderRadius: '8px'
    },
  });

  const textStyles = useBreakpointValue({
    base: { color: 'rgba(255, 255, 255, 0.8)', fontSize: '20px' },
    sm: { color: 'rgba(255, 255, 255, 0.8)', fontSize: '22px' },
    
  });

  const TEXT_STYLES3 = {
    fontSize: { base: "13.5px", sm: "16px" },
    color: "whiteAlpha.900",
  };

  const TEXT_STYLES4 = {
    fontSize: { base: "11px", sm: "13.5px"},
    color: "whiteAlpha.800",
  };

  const TEXT_STYLES5 = {
    fontSize: { base: "14px", sm: "15.5px" },
    color: "yellow.500",
  };

  const TEXT_STYLES6 = {
    fontSize: { base: "12px", sm: "13.5px"},
    color: "whiteAlpha.800",
  };

  const TEXT_STYLES7 = {
    fontSize: { base: "12px", sm: "13.5px"},
    color: "blue.500",
  };

  const CheckmarkContainer = ({ children }) => (
    <Box display="flex" alignItems="center" gap="1rem" mt="20px">
        <Box minWidth="30px" display="flex" alignItems="center" justifyContent="flex-start">
        <Checkmark size="22px" />
      </Box>
      <Text {...TEXT_STYLES3}>{children}</Text>
    </Box>
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    
    <Button sx={buttonStyles}>
      <Box style={svgStyles} bg="#1C0D28" overflow="hidden" display="flex" flexDirection="column" p="20px">
        <Text style={textStyles} justifyContent='center' alignItems='center'>Benefits</Text>

        <CheckmarkContainer>Base share price</CheckmarkContainer>
        <Text {...TEXT_STYLES3}  ml={{base: "2.1rem", sm:"0.5rem"}}>increases annually</Text>
        <CheckmarkContainer>Share revenue</CheckmarkContainer>
        <Text {...TEXT_STYLES4} ml={{base: "-8rem", sm:"-11rem"}}>Share 25%</Text>
        <Text {...TEXT_STYLES4} ml={{base: "-0.2rem", sm:"-1.5rem"}}>in the investor pool</Text>
        <Text {...TEXT_STYLES4} ml={{base: "3rem", sm:"2.5rem"}}>of all products revenue.</Text>
        <CheckmarkContainer>NFT sales profit</CheckmarkContainer>
        <Text {...TEXT_STYLES3}  ml={{base: "-8.3rem", sm:"-12rem"}}> sharing</Text>
        <CheckmarkContainer>Share buyback option</CheckmarkContainer>
        <CheckmarkContainer>DAO Tokens</CheckmarkContainer>

        <a href="https://angry-web3.gitbook.io/angry-web3-litepaper/investment-opportunities" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
    <Text {...TEXT_STYLES7} mt="30px">More details</Text>
</a>

        <Text {...TEXT_STYLES6} as="button" onClick={openModal} mt="30px">Revenue Distribution Chart</Text>
        <Text {...TEXT_STYLES5} as="button" onClick={openModal} mt="10px">Click here</Text>
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          style={{
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              width: '95%',
              maxWidth: '700px',
              borderRadius: '10px',
              backgroundColor: '#301644',
            }
          }}
        >
          <img src="../Ecomap.png" alt="Ecosystem Chart" />
          <button onClick={closeModal} style={{ marginTop: '10px', color: 'white' }}>Close</button>
        </Modal>
      </Box>
    </Button>
  );
};

export default Card5;
