import React from 'react';

const stakeholders = [
  { name: 'Founder', percentage: 51 },
  { name: 'Sasi', percentage: 15 },
  { name: 'Roacharry', percentage: 10 },
  { name: 'Willy', percentage: 3 },
  { name: 'Lena', percentage: 4 },
  { name: 'Bebe', percentage: 3 },
  { name: 'Boom', percentage: 2 },
  { name: 'Philip', percentage: 1 },
  { name: 'Phoenix', percentage: 1 },
  { name: 'Pete', percentage: 3 },
  { name: 'Tom', percentage: 2 },
  { name: 'Queen', percentage: 1 },
  { name: 'EPEO', percentage: 1 },
  { name: 'Available', percentage: 3 }, // Assuming 'Available' means unallocated
];

const totalSeats = 100;
const gridSize = 10; // Set the grid size to 10x10

const PieChart = () => {
  stakeholders.sort((a, b) => {
    if (a.name === 'Available') return 1;
    if (b.name === 'Available') return -1;
    return b.percentage - a.percentage;
  });

  const colors = [
    'rgba(255, 99, 132, 0.6)',
    'rgba(54, 162, 235, 0.6)',
    'rgba(64, 12, 165, 0.6)',
    'rgba(255, 206, 86, 0.6)',
    'rgba(75, 192, 192, 0.6)',
    'rgba(153, 102, 255, 0.6)',
    'rgba(255, 255, 0, 0.6)',
    'rgba(400, 88, 0, 0.6)',
    'rgba(128, 0, 128, 0.6)',
    'rgba(771, 113, 417, 0.6)',
    'rgba(25, 3, 412, 0.6)',
    'rgba(42, 27, 112, 0.6)',
    'rgba(122, 7, 182, 0.6)',
    'rgba(201, 203, 207, 0.6)', // Unique color for 'Available'
  ];

  let seatPositions = [];
  stakeholders.forEach((stakeholder, index) => {
    const seatsForStakeholder = Math.round((stakeholder.percentage / 100) * totalSeats);
    for (let i = 0; i < seatsForStakeholder; i++) {
      const fontSize = stakeholder.name === 'Roacharry' ? '6px' : '7px';
      const textColor = stakeholder.name === 'Available' ? 'yellow' : 'rgba(255, 255, 255, 0.9)';
      seatPositions.push({ name: stakeholder.name, color: colors[index], fontSize, textColor });
    }
  });

  const grid = Array(gridSize)
    .fill(null)
    .map(() => Array(gridSize).fill(null));

  let rowIndex = 0;
  let colIndex = 0;

  seatPositions.forEach(seat => {
    grid[rowIndex][colIndex] = seat;
    colIndex++;
    if (colIndex === gridSize) {
      colIndex = 0;
      rowIndex++;
    }
  });

  return (
    <div>
      <div style={{ display: 'grid', gridTemplateColumns: `repeat(${gridSize}, 1fr)`, gap: '2px', maxWidth: '600px', margin: '20px auto' }}>
        {grid.map((row, rowIndex) =>
          row.map((seat, colIndex) => (
            <div key={`${rowIndex}-${colIndex}`} style={{
              width: '50px',
              height: '50px',
              backgroundColor: seat.color,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: seat.textColor,
              fontSize: seat.fontSize,
              border: '1px solid #ddd',
              position: 'relative',
            }}>
              {seat.name}
            </div>
          ))
        )}
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', maxWidth: '650px', margin: '0 auto' }}> {/* Adjust this maxWidth for the label wrapper */}
        {stakeholders.map((stakeholder, index) => (
          <div key={index} style={{ display: 'flex', alignItems: 'center', marginRight: '10px', marginBottom: '5px' }}>
            <div style={{ width: '20px', height: '20px', backgroundColor: colors[index], marginRight: '5px' }}></div>
            <div style={{ fontSize: '10px', color: 'rgba(255, 255, 255, 0.9)' }}>{stakeholder.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PieChart;
