import React from 'react';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { Box, Image, Flex} from '@chakra-ui/react';
import { Global, css } from "@emotion/react";

const RoadmapCard = ({ Data }) => (
    <VerticalTimelineElement
      contentStyle={{ 
        background: 'linear-gradient(to right, #9956D0, #1C0D28)', 
        color:"#fff"
      }}
      contentArrowStyle={{ borderRight: '7px solid transparent' }}
      date={Data.date}
      iconStyle={{ background: Data.iconBg, overflow: 'hidden' }} // Add overflow hidden here
      icon={
        <Flex justify="center" align="center" w="full" h="full">
          <Image
            src={Data.icon}
            alt={Data.event_name}
            boxSize="100%" // Increase boxSize to make the logo bigger
            objectFit="cover" // Change to cover to ensure the logo covers the circular area
          />
        </Flex>
      }
    >
      <Box fontFamily="'Lexend Mega', sans-serif" color="whiteAlpha.900" fontSize={{base: '21px', sm: '25px', md: '28px', lg: '30px'}}>
        {Data.title}
      </Box>
      <Box fontFamily="'Lexend Mega', sans-serif" color="whiteAlpha.800" fontSize={{base: '14px', sm: '16px', md: '18px', lg: '20px'}} m={0}>
        {Data.event_name}
      </Box>
      <Box as="ul" mt={5} ml={5} listStyleType="disc">
          {Data.info.map((point, index) => (
            <Box 
              as="li"
              mt={1}
              key={`Data-point-${index}`} 
              color="whiteAlpha.900" 
              fontSize={{ base: '14px', sm: '18px', md: '20px', lg: '22px' }} 
              mb={3} // Add margin-bottom to create space between list items
            >
              {point}
          </Box>
        ))}
      </Box>
    </VerticalTimelineElement>
  );
  
  const Roadmap = () => {
    
    return (
      <>
<Global
  styles={css`
    .vertical-timeline-element-date {
      font-size: 12px !important;
    }
  `}
/>

      <Box
        w="100vw"
        minHeight="100vh" 
        bg="#1C0D28"
        bgPos="center"
        bgSize="cover"
        bgRepeat="no-repeat"
        position="relative"
        overflowX="hidden"
        css={{
          width: '-webkit-fill-available',
          minHeight: '-webkit-fill-available', 
          '@supports not (-webkit-touch-callout: none)': {
            width: '100vw',
            minHeight: '100vh', 
          }
        }}
      >
      <Flex justify="center" align="center" mt="1px">
      <Image
          src="/firep.png"
          alt="Fire icon"
          mr={{ base: "2", sm: "2", lg: "4" }}
          ml={{ base: "-5", sm: "-7", lg: "0" }}
          mb={3}
          width={{ base: "30px", sm: "40px", md: "50px" }}
          height={{ base: "40px", sm:"50px", md: "60px" }}
          />
        <Box
            color="whiteAlpha.900"
            fontSize={{ base: "24px", sm: "28px", md: "36px", lg: "42px"}}
            fontWeight="black"
            fontFamily="'Lexend Peta', sans-serif"
            justify="center" 
            align="center"
          >
            ROADMAP
          </Box>
      </Flex>
        <VerticalTimeline>
          {Data.map((data, index) => (
            <RoadmapCard
              key={`data-${index}`}
              Data={data}
            />
          ))}
        </VerticalTimeline>
      </Box>
      </>
    );
  };
  

// Data
const Data = [
    {
      title: "Q1",
      event_name: "2024",
      icon: "/Roadmap1.png",
      iconBg: "#383E56",
      date: "JANUARY - MARCH",
      info: [
        " • Launched the ANGRY WEB3.",
        " • Opening for shareholders.",
        " • Distribute token for DAO.",
        " • Fundraising for a casino through NFT sales.",
        " • Officially launch the ANGRY Casino.",
      ],
    },
    {
      title: "Q2",
      event_name: "2024",
      icon: "/Roadmap2.png",
      iconBg: "#E6DEDD",
      date: "APRIL - JUNE",
      info: [
        " • Launch the ANGRY DEX.",
        " • Token staking.",
      ],
    },
    {
      title: "Q3",
      event_name: "2024",
      icon: "/Roadmap1.png",
      iconBg: "#383E56",
      date: "JULY - SEPTEMBER",
      info: [
        " • Angry NFT MARKET.",
        " • NFT staking.",
      ],
    },
    {
      title: "Q4",
      event_name: "2024",
      icon: "/Roadmap2.png",
      iconBg: "#E6DEDD",
      date: "OCTOBER - DECEMBER",
      info: [
        " • Explore new products and enhancements.",
        " • Community events.",
        " • Revenue distribution"
      ],
    },
  ];

export default Roadmap;
