import React, { useState, useEffect } from 'react';
import { Box, Flex, Image, Text, Spinner} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { IoIosReturnLeft } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import OrientationWarning from './Rotate';
import { useMediaQuery } from '@chakra-ui/react';
import PieChart from './Piechart';
import Card5 from './CardStyle5';
import Buybutton from './Buybutton';

const Investor = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [isSmallScreen] = useMediaQuery("(max-width: 768px)");

    // Framer Motion variants for fade-out effect
    const fadeOut = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 },
        exit: { opacity: 0 }
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 1000);
        return () => clearTimeout(timer);
    }, []);

    const TEXT_STYLES = {
        color: "whiteAlpha.900",
        fontSize: { base: "30px", sm: "34px", md: "36px", lg: "42px" },
        fontWeight: "black",
        fontFamily: "'Lexend Peta', sans-serif"
    };

    const TEXT_STYLES2 = {
        color: "whiteAlpha.900",
        fontSize: { base: "24px", sm: "28px", md: "32px", lg: "36px" },
        fontWeight: "black",
        fontFamily: "'Lexend Peta', sans-serif"
    };

    const TEXT_STYLES3 = {
        color: "whiteAlpha.800",
        fontSize: { base: "14px", sm: "15px", md: "16px", lg: "18px" },
    };

    const TEXT_STYLES4 = {
        color: "#ffd966",
        fontSize: { base: "14px", sm: "15px", md: "16px", lg: "18px" },
    };

    const handleReturnClick = () => navigate('/');

    const linkStyle = {
        p: "2",
        borderRadius: "md",
        _hover: {
            bg: "#461874",
            borderRadius: "md",
        },
    };

    if (isLoading) {
        return (
            <motion.div
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={fadeOut}
                transition={{ duration: 0.5 }}
            >
                <Box
                    w="100vw"
                    h="100vh"
                    bg="#1C0D28"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Image width="200px" height="auto" src="/Investor.png" alt="Investor" />
                    <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="purple.400" size="xl" mt="4"/>
                </Box>
            </motion.div>
        );
    }
    
    return (
        <Box
            w="100vw"
            bg="#1C0D28"
            bgPos="center"
            bgSize="cover"
            bgRepeat="no-repeat"
            position="relative"
            overflowX="hidden"
            overflowY="hidden"
            css={{
                '@supports not (-webkit-touch-callout: none)': {
                    width: '100vw'
                }
            }}
        >
            {isSmallScreen && <OrientationWarning />}
            {/* Navigation */}
            <Flex
                as="nav"
                align="center"
                justify="space-between"
                wrap="wrap"
                padding="1.5rem"
                bg="#1C0D28"
                color="white"
                position="absolute"
                width="full"
                zIndex={2}
            >
                <Box position="relative" ml={{ base: '0rem', sm: "1rem" ,md: '1rem', lg: '2.5rem' }}  mt={{base: '0.3rem', sm: '0rem'}}>
                    <Image
                        src="/icon.png"
                        w={{ base: '45px', sm: '50px' }}
                        h={{ base: '45px', sm: '50px' }}
                    />
                    <Box
                        position="absolute"
                        top="0"
                        left="0"
                        right="0"
                        bottom="0"
                        cursor="pointer"
                        onClick={handleReturnClick}
                    />
                </Box>
                <Box {...linkStyle} onClick={handleReturnClick} mr={{ base: '-0.5rem', sm: '0.3rem', md: '0.5rem', lg: '2.7rem' }}>
                    <IoIosReturnLeft size="36px" />
                </Box>
            </Flex>
            
                <Box textAlign="center" pt="10rem" align-items="center" maxW="1250px" mx="auto"> 
                    <Text {...TEXT_STYLES2}>
                        INVESTORS
                    </Text>

                    <Box mt="1rem" mr="2rem" ml="2rem">
                        <Text {...TEXT_STYLES3}>
                        Welcome to the ANGRY WEB3 Investor Hub. This section is dedicated to investors who have already partnered with us in the early stages, as well as those who are eager to begin their journey with us. To provide a brief overview, we are offering 49% of our shares to the public.
                        </Text>
                    </Box>

                    <Box mr="2rem" ml="2rem" mt="2rem">
                        <Text {...TEXT_STYLES4}>
                        "We would like to express our heartfelt gratitude to all of you for your trust and participation in this project"
                        </Text>
                    </Box>

                    <Box mt="2rem" mr="2rem" ml="2rem">
                        <Text {...TEXT_STYLES3}>
                        As you may know, achieving widespread recognition for a project requires more than just outstanding products; effective marketing is also crucial. Therefore, a significant portion of our funds will be allocated to marketing campaigns, as well as covering system fees associated with the development of our project. These include expenses for artists, 3D NFT rendering, programmers, EVM fees, and influencers.
                        </Text>
                    </Box>

                    <Box mt="2rem" mr="2rem" ml="2rem">
                        <Text {...TEXT_STYLES3}>
                        We are committed to the future and promise to exert our utmost efforts for the success of this project. Our dedication is unwavering, and we will persevere until we achieve our goals.
                        </Text>
                    </Box>

                    <Box textAlign="center" pt="10rem" align-items="center" maxW="1500px" mx="auto"> 
                        <Text {...TEXT_STYLES}>
                            AVAILABLE NOW 
                        </Text>

                        <Box mt="1.5rem" display="flex" justifyContent="center" alignItems="center" w="full">
                        {/* <stripe-buy-button
  buy-button-id="buy_btn_1OZi0pJeI0l2OVxKEvqBBSmK"
  publishable-key="pk_live_51OZHxVJeI0l2OVxK9fztRNQvfAaH0uGAGritO58cwuAPocqubjVZn8KHgRyeMcLM3nHjJvXwSGGYnhxVmdoamb8e006wkRbmgf"
>
        </stripe-buy-button> */}
                       <Buybutton/>
                        </Box>
                        <Box mt="3rem">
                            <Card5/>
                        </Box>
                    </Box>

                    <Box pb="1rem" textAlign="center" pt="10rem" align-items="center" maxW="1200px" mx="auto"> 
                        <Text {...TEXT_STYLES2}>
                            ANNOUCEMENT
                        </Text>

                        <Box mt="1rem" mr="2rem" ml="2rem">
                        <Text {...TEXT_STYLES3}>
                         Current price : 500 USD (1/1/24)
                        </Text>
                        <Text {...TEXT_STYLES3}>
                         2024 Revenue: TBA (31/12/24)
                        </Text>
                        </Box>

                    </Box>

                    <Box pb="1rem" textAlign="center" pt="6rem" align-items="center" maxW="2000px" mx="auto"> 
                        <Text {...TEXT_STYLES2}>
                            STAKEHOLDERS
                        </Text>
                    </Box>

                    <PieChart />

                </Box>
            <Footer />
        </Box>
    );
};

export default Investor;
