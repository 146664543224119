import React, { useState, useEffect } from 'react';

function useResponsiveHeight() {
    const [height, setHeight] = useState('90px'); // default base height

    useEffect(() => {
        const updateHeight = () => {
            const width = window.innerWidth;
            if (width >= 1280) { // xl: not used in your case, but shown for completeness
                setHeight('120px'); // or any other value you want for xl
            } else if (width >= 992) { // lg
                setHeight('120px');
            } else if (width >= 768) { // md
                setHeight('110px');
            } else if (width >= 480) { // sm
                setHeight('100px');
            } else { // base
                setHeight('90px');
            }
        };        

        window.addEventListener('resize', updateHeight);
        updateHeight(); // Initialize height on mount

        return () => window.removeEventListener('resize', updateHeight);
    }, []);

    return height;
}

function Cardlong({ w, _hover, transition, onClick, cursor, svgText}) {
    const responsiveHeight = useResponsiveHeight();

        return (
            <div 
                style={{ 
                    width: w, 
                    height: responsiveHeight, 
                    transition: transition, 
                    cursor: cursor
                }}
                onClick={onClick}
                onMouseEnter={(e) => e.currentTarget.style.transform = _hover.transform}
                onMouseLeave={(e) => e.currentTarget.style.transform = 'none'}
            >
            <svg 
            width="100%" 
            height="100%" 
            viewBox="0 0 253 123" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            >
        <svg 
            width="253" 
            height="123" 
            viewBox="0 0 253 123" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_d_744_676)">
            <rect 
                x="32" 
                y="28" 
                width="189" 
                height="59" 
                rx="24" 
                fill="#1C0D28"
            />
            <rect 
                x="32.5" 
                y="28.5" 
                width="188" 
                height="58" 
                rx="23.5" 
                stroke="url(#paint0_linear_744_676)" 
                strokeOpacity="0.4"
            />
            </g>
            <defs>
            <filter 
                id="filter0_d_744_676" 
                x="0" 
                y="0" 
                width="253" 
                height="123" 
                filterUnits="userSpaceOnUse" 
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feColorMatrix 
                in="SourceAlpha" 
                type="matrix" 
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" 
                result="hardAlpha"
                />
                <feOffset dy="4"/>
                <feGaussianBlur stdDeviation="16"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix 
                type="matrix" 
                values="0 0 0 0 0.213026 0 0 0 0 0.114602 0 0 0 0 0.291764 0 0 0 1 0"
                />
                <feBlend 
                mode="normal" 
                in2="BackgroundImageFix" 
                result="effect1_dropShadow_744_676"
                />
                <feBlend 
                mode="normal" 
                in="SourceGraphic" 
                in2="effect1_dropShadow_744_676" 
                result="shape"
                />
            </filter>
            <linearGradient 
                id="paint0_linear_744_676" 
                x1="32" 
                y1="28" 
                x2="251.797" 
                y2="77.9089" 
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="white"/>
                <stop offset="0.5" stopColor="white" stopOpacity="0.29"/>
                <stop offset="1" stopColor="white"/>
            </linearGradient>
            </defs>
            </svg>
                <text
                        x="50%"
                        y="47%"
                        dominantBaseline="middle"
                        textAnchor="middle"
                        style={{
                            fill: 'white', // For color
                            fontSize: '16px', // Adjust as needed
                            fontFamily: "'Lexend Mega', sans-serif",
                        }}
                    >
                    {svgText}
                </text>
        </svg>
    </div>
    );
  }
  
export default Cardlong;