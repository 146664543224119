import React, { useState, useEffect } from 'react';
import { Box, Flex, Image, Text, Spinner, Progress, Center,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { IoIosReturnLeft } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import OrientationWarning from './Rotate';
import { useMediaQuery } from '@chakra-ui/react';
import Card3 from './CardStyle3';
import Card4 from './CardStyle4';

const Angrypass = () => {

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [isSmallScreen] = useMediaQuery("(max-width: 768px)");

    const maxSupply = 5000;
    const remaining = 5000;
    const progressValue = ((remaining) / maxSupply) * 100;

    // Framer Motion variants for fade-out effect
    const fadeOut = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 },
        exit: { opacity: 0 }
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 1500);
        return () => clearTimeout(timer);
    }, []);
    

    const TEXT_STYLES2 = {
        color: "whiteAlpha.900",
        fontSize: { base: "16px", sm: "22px", md: "30px", lg: "34px" },
        fontWeight: "black",
        fontFamily: "'Lexend Peta', sans-serif"
    };

    const TEXT_STYLES3 = {
        fontSize: { base: "9px", sm: "12.5px", md: "13.5px", lg: "16px" },
        color: "whiteAlpha.500",
        fontStyle: "italic",
    };

    const TEXT_STYLES20 = {
        color: "whiteAlpha.900",
        fontSize: { base: "16px", sm: "22px", md: "30px", lg: "34px" },
        fontWeight: "black",
        fontFamily: "'Lexend Peta', sans-serif"
    };

    const TEXT_STYLES5 = {
        color: "whiteAlpha.900",
        fontSize: { base: "14px", sm: "16px", md: "18px", lg: "20px" },
        fontWeight: "black",
        fontFamily: "'Lexend Peta', sans-serif"
    };

    const TEXT_STYLES50 = {
            color: "whiteAlpha.900",
            fontSize: { base: "24px", sm: "28px", md: "32px", lg: "36px" },
            fontWeight: "black",
            fontFamily: "'Lexend Peta', sans-serif"
        };

    const TEXT_STYLES49 = {
        color: "whiteAlpha.800",
        fontSize: { base: "14px", sm: "15px", md: "16px", lg: "18px" },
    };

    const handleReturnClick = () => navigate('/');

    const linkStyle = {
        p: "2",
        borderRadius: "md",
        _hover: {
            bg: "#461874",
            borderRadius: "md",
        },
    };

    const TEXT_STYLES = {
        color: "whiteAlpha.900",
        fontSize: { base: "16px", sm: "20px", md: "25px", lg: "30px" },
        fontWeight: "black",
        fontFamily: "'Lexend Peta', sans-serif",
    };

    const downloadExcel = (fileName) => {
        const link = document.createElement("a");
        link.href = `/${fileName}.xlsx`; // Update the path
        link.download = `${fileName}.xlsx`;
        link.click();
    };
    
    const downloadTextStyle = {
        color: "blue.500",
        fontWeight: "500",
        cursor: "pointer",
    };


    if (isLoading) {
        return (
            <motion.div
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={fadeOut}
                transition={{ duration: 0.5 }}
            >
                <Box
                    w="100vw"
                    h="100vh"
                    bg="#1C0D28"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Text {...TEXT_STYLES}>Take a part in our projects</Text>
                    <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="purple.400" size="xl" mt="4"/>
                </Box>
            </motion.div>
        );
    }
    
    
    return (
        <Box
            w="100vw"
            bg="#1C0D28"
            bgPos="center"
            bgSize="cover"
            bgRepeat="no-repeat"
            position="relative"
            overflowX="hidden"
            overflowY="hidden"
            css={{
                '@supports not (-webkit-touch-callout: none)': {
                    width: '100vw'
                }
            }}
        >
            {isSmallScreen && <OrientationWarning />}
            {/* Navigation */}
            <Flex
                as="nav"
                align="center"
                justify="space-between"
                wrap="wrap"
                padding="1.5rem"
                bg="#1C0D28"
                color="white"
                position="absolute"
                width="full"
                zIndex={2}
            >
                <Box position="relative" ml={{ base: '0rem', sm: "1rem" ,md: '1rem', lg: '2.5rem' }}  mt={{base: '0.3rem', sm: '0rem'}}>
                    <Image
                        src="/icon.png"
                        w={{ base: '45px', sm: '50px' }}
                        h={{ base: '45px', sm: '50px' }}
                    />
                    <Box
                        position="absolute"
                        top="0"
                        left="0"
                        right="0"
                        bottom="0"
                        cursor="pointer"
                        onClick={handleReturnClick}
                    />
                </Box>
                <Box {...linkStyle} onClick={handleReturnClick} mr={{ base: '-0.5rem', sm: '0.3rem', md: '0.5rem', lg: '2.7rem' }}>
                    <IoIosReturnLeft size="36px" />
                </Box>
            </Flex> 
            
            <Box textAlign="center" pt="10rem" align-items="center" maxW="1250px" mx="auto"> 
                    <Text {...TEXT_STYLES2}>
                        ANGRY PASS HOLDERS
                    </Text>
                    <Text {...TEXT_STYLES5}>
                     Casino Fundraising Series
                    </Text>

                <Box textAlign="center" pt="2rem" align-items="center" maxW={{  sm: "600px", md: "790px", lg: "950px" }} mx="auto"> 
                    <Text {...TEXT_STYLES3}>
                        This page contains the same information as the "Angry Pass" section on the NFT page, but offers more detailed and daily updates for holders and products.
                    </Text>
                </Box>

                <Flex justifyContent="center" alignItems="center" flexDirection={{ base: "column", lg: "row" }}>
                    <Box pr={{ base: 0, lg: 50 }} mt={{ base: 5, lg: "4rem"}} order={{ base: 2, lg: 1 }}>
                        <Flex flexDirection="column" gap="2rem">
                            {/* Card4 will be displayed above Card3 on md screens and below */}
                            <Box order={{ base: 2, lg: 0 }}>
                                <Card3 />
                            </Box>
                            <Box order={{ base: 1, lg: 0}}>
                                 <Text textAlign="left" fontSize="14px" textColor="whiteAlpha.800" mb={2}>Total Supply: {remaining} / {maxSupply}</Text>
                                 <Progress
                                    value={progressValue}
                                    colorScheme="green"
                                    hasStripe 
                                    sx={{
                                        borderRadius: 'full', // For rounded corners
                                        height: '8px', // Adjust size as needed
                                        mb: 5
                                    }}
                                />
                                <Card4/>

                            </Box>
                        </Flex>
                    </Box>

                        <Box order={{ base: 1, lg: 2 }}>
                            <Box display="flex" justifyContent="center" alignItems="center" height="20vh">
                            <motion.div
                                initial={{ x: -100, opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ type: 'spring', stiffness: 50 }}
                            >
                                <Image src='./Angryroll.png' w={{md: '400px', sm: '350px', base: '320px'}} h='auto' />
                            </motion.div>
                            </Box>
                            <Text {...TEXT_STYLES20}>X</Text>
                            <Box display="flex" justifyContent="center" alignItems="center" >
                            <motion.div
                                initial={{ x: 100, opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ type: 'spring', stiffness: 50 }}
                            >
                                <Image src='./Angrypassh.png' w={{md: '350px', sm: '300px', base: '250px'}} h='auto' />
                            </motion.div>
                            </Box>
                        </Box>
                      </Flex>
                    </Box>
                <Box>
            </Box>

            <Box  mt="5rem" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    <Text {...TEXT_STYLES50}> ANNOUNCEMENT </Text>
                    <Text mt="1rem" {...TEXT_STYLES49}>Whitelist collections: TBA </Text>
                    <Text {...TEXT_STYLES49}>Events: TBA </Text>

                    <Text mt="5rem" {...TEXT_STYLES50}> ANGRY POOL </Text>
                    <Text  mt="1rem" {...TEXT_STYLES49}>Current value: TBA </Text>
                    <Text {...TEXT_STYLES49}>2024 Dividend: TBA (31/12/24) </Text>
                    <Text mt="5rem" {...TEXT_STYLES50}>Holders list </Text>
                <Box  mt="1rem">
                    <Center>
                    <Text mt="0.5rem" {...TEXT_STYLES49}>
                        Hedera network
                    </Text>
                    </Center>
                    <Center>
                    <Text {...downloadTextStyle} onClick={() => downloadExcel("hedera")}>
                        Download
                    </Text>
                    </Center>
                    <Center>
                    <Text mt="1rem" {...TEXT_STYLES49}>
                        Polygon chain
                    </Text>
                    </Center>
                    <Center>
                    <Text {...downloadTextStyle} onClick={() => downloadExcel("polygon")}>
                        Download
                    </Text>
                    </Center>
                </Box>
            </Box>

            <Footer />
        </Box>
    );
};

export default Angrypass;
