import React from 'react';
import {
  Box,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';

const ButtoninNFT = ({ buttonName }) => {

  const buttonStyles = useBreakpointValue({
    base: {
      width: '70px',
      height: '40px',
      padding: '1px',
      borderRadius: '30px',
      overflow: 'hidden',
      bgGradient: 'linear(to right, #9B9292 0%, rgba(255, 255, 255, 0.29) 50%, #9B9292 100%)',
    },
    sm: {
      width: '80px',
      height: '40px',
      padding: '1px',
      borderRadius: '30px',
      overflow: 'hidden',
      bgGradient: 'linear(to right, #9B9292 0%, rgba(255, 255, 255, 0.29) 50%, #9B9292 100%)',
    },
    md: {
      width: '100px',
      height: '40px',
      padding: '1px',
      borderRadius: '30px',
      overflow: 'hidden',
      bgGradient: 'linear(to right, #9B9292 0%, rgba(255, 255, 255, 0.29) 50%, #9B9292 100%)',
    },
    lg: {
      width: '120px',
      height: '40px',
      padding: '1px',
      borderRadius: '30px',
      overflow: 'hidden',
      bgGradient: 'linear(to right, #9B9292 0%, rgba(255, 255, 255, 0.29) 50%, #9B9292 100%)',
    },
  });

  const svgStyles = useBreakpointValue({
    base: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '30px'
    },
    sm: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '30px'
    },
  });

  const textStyles = useBreakpointValue({
    base: { color: 'rgba(255, 255, 255, 0.8)', fontSize: '14px' },
    sm: { color: 'rgba(255, 255, 255, 0.8)', fontSize: '14px' },
  });

  return (
    <>
      <Box 
        sx={{
          ...buttonStyles,
          
        }}
        
      >
        <Box style={svgStyles} bg="#1C0D28" overflow="hidden">
          <Text style={textStyles}>
            {buttonName}
          </Text>
        </Box>
      </Box>

    </>
  );
};

export default ButtoninNFT;
