import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import All from './components/All';
import NFTs from './components/NFTs'; 
import Token from './components/Token';
import Investor from './components/Investor';
import Aboutus from './components/Aboutus';
import Angrypass from './components/Angrypass';

function App() {
  return (
    <Router> {/* Use Router to wrap the application */}
      <div className="App">
        <Routes> {/* Use Routes instead of Switch */}
          <Route path="/" element={<All />} /> 
          <Route path="/NFTs" element={<NFTs />} /> 
          <Route path="/Token" element={<Token />} />
          <Route path="/Investor" element={<Investor />} />
          <Route path="/Aboutus" element={<Aboutus />} />
          <Route path="/Angrypass" element={<Angrypass/>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
