import React from 'react';
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Text,
  Image,
  Flex,
  Progress
} from '@chakra-ui/react';

const Card4 = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  {/* SentX */}
  const maxSupply1 = 1000;
  const remaining1 = 1000;
  const progressValue1 = ((remaining1) / maxSupply1) * 100;

  {/* TierBot */}
  const maxSupply2 = 1000;
  const remaining2 = 1000;
  const progressValue2 = ((remaining2) / maxSupply2) * 100;

  {/* Zues */}
  const maxSupply3 = 1000;
  const remaining3 = 1000;
  const progressValue3 = ((remaining3) / maxSupply3) * 100;

  {/* OpenSea */}
  const maxSupply4 = 1000;
  const remaining4 = 1000;
  const progressValue4 = ((remaining4) / maxSupply4) * 100;

  {/* OKX */}
  const maxSupply5 = 1000;
  const remaining5 = 1000;
  const progressValue5 = ((remaining5) / maxSupply5) * 100;

  return (
    <>
<Box
  position="relative"
  w={{ base: "280px", sm: "330px" }}
  h={{ base: "60.28px", md: "74px", lg: "74px" }}
  bg="#4a4a4a"
  // bgGradient="linear(to-r, #D83A56, #92513C)"
  borderRadius="16px"
  zIndex={0}
  opacity="1"
>

{/* 
      <Button
        top="-6px"
        bgGradient="linear(to-r, #F84565, #F88965)"
        color="whiteAlpha.900"
        fontSize={{ base: "16px",md: "20px", lg: "24px"}} // Increased font size for larger screens
        borderRadius="16px"
        w="100%"
        h="100%"
        _hover={{
          bgGradient: "linear(to-r, #F97575, #F89B75)"
        }}
        _active={{
          top: "0px",
          left: "0px",
          bgGradient: "linear(to-r, #F83555, #F87955)"
        }}
        zIndex={1}
        onClick={onOpen}
      >
        MINT NOW
      </Button>
*/}
<Button
  top="-6px"
  bg="#757575" // Solid grey background
  color="whiteAlpha.900"
  fontSize={{ base: "16px", md: "20px", lg: "24px" }} // Increased font size for larger screens
  borderRadius="16px"
  w="100%"
  h="100%"
  _hover={{
    bg: "#8d8d8d" // Slightly lighter grey for hover state
  }}
  _active={{
    top: "0px",
    left: "0px",
    bg: "#616161" // Slightly darker grey for active state
  }}
  zIndex={1}
>
  Coming soon
</Button>



    </Box>

      {/* Popup Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent 
          width={{ base: "90%", md: "80%", lg: "70%" }}  // Custom width responsive
          maxWidth="500px"  // Maximum width limit
          height={{base:"650px",sm:"600px"}}  // Custom height
          bgColor="#1C0D28"
          borderRadius="15px"
        >
          <ModalHeader mt="3rem" fontSize="20px" textColor="whiteAlpha.900" textAlign="center">Available Launchpad</ModalHeader>
                  <ModalCloseButton p="1rem" size="20px" color="grey" />
                    
                  <ModalBody p="3rem">
                    <Text textColor="whiteAlpha.900" pb="3rem">Hedera network : 188 HBAR </Text>
                    
                    <Flex direction="row" justify="space-around">

                    <Box>
                        <Flex direction="column" alignItems="center">
                            <a href="https://external-link-1.com" target="_blank" rel="noopener noreferrer">
                            <Image w={{base:"70px", sm: "80px"}} h={{base:"70px", sm: "80px"}} src="../launchpad logos/sentlogo.webp"/>
                            <Text textColor="whiteAlpha.900" textAlign="center">Sentx</Text>
                            </a>
                            <Progress
                            value={progressValue1}
                            colorScheme="green"
                            hasStripe 
                            sx={{
                                borderRadius: 'full',
                                height: '8px',
                                mb: 2,
                                width: '100%' // Ensure progress bar stretches to the width of the container
                            }}
                            />
                            <Text textColor="whiteAlpha.900" fontSize={{base:"11px", sm: "12px"}} textAlign="center">1000/1000</Text>
                        </Flex>
                    </Box>
                    
                      <Box ml={{base:"0.75rem", sm:"1rem"}} mr={{base:"0.7rem", sm:"0rem"}}>
                          <Flex direction="column" alignItems="center">
                                <a href="https://external-link-2.com" target="_blank" rel="noopener noreferrer">
                                <Box>
                                    <Image ml={{base:"1.1rem", sm:"1.1rem"}} w={{base:"60px", sm: "75"}} h={{base:"70px", sm: "80px"}} src="../launchpad logos/nfttier.svg"/>
                                </Box>
                                <Text textColor="whiteAlpha.900" textAlign="center">TierBot</Text>
                                </a>
                                <Progress
                                width="80px"
                                value={progressValue2}
                                colorScheme="green"
                                hasStripe 
                                sx={{
                                    borderRadius: 'full', // For rounded corners
                                    height: '8px', // Adjust size as needed
                                    mb: 2}}/>
                                <Text textColor="whiteAlpha.900" fontSize={{base:"11px", sm: "12"}}>1000/1000</Text>
                            </Flex>
                      </Box>

                    <Box>
                        <Flex direction="column" alignItems="center" textAlign="center">
                            <a href="https://external-link-2.com" target="_blank" rel="noopener noreferrer">
                            <Image ml={{base:"0rem", sm: "-0.05rem"}} w={{base:"80px", sm: "90px"}} h={{base:"70px", sm: "80px"}} src="../launchpad logos/zues.png"/>
                            <Text  textAlign="center" textColor="whiteAlpha.900">Zues</Text>
                            </a>
                            <Progress
                           
                            width="80px"
                            value={progressValue3}
                            colorScheme="green"
                            hasStripe 
                            sx={{
                                borderRadius: 'full', // For rounded corners
                                height: '8px', // Adjust size as needed
                                mb: 2}}/>
                            <Text  textColor="whiteAlpha.900" fontSize={{base:"11px", sm: "12"}}>1000/1000</Text>
                         </Flex>
                    </Box>

                    </Flex>

                        <Text textColor="whiteAlpha.900" pb="3rem" pt="4rem">Polygon chain : 18 MAIC </Text>
                    
                        <Flex direction="row" justify="space-around">

                        <Box
                         direction="column" alignItems="center" textAlign="center">
                            <a href="https://external-link-2.com" target="_blank" rel="noopener noreferrer">
                            <Image ml={{base:"1.8rem",sm:"1.2rem"}} w={{base:"60px", sm: "70px"}} h={{base:"60px", sm: "80px"}} src="../launchpad logos/opensea-logo.svg"/>
                            <Text textAlign="center" textColor="whiteAlpha.900">OpenSea</Text>
                            </a>
                            <Progress
                            value={progressValue4}
                            colorScheme="green"
                            hasStripe 
                            sx={{
                                borderRadius: 'full', // For rounded corners
                                height: '8px', // Adjust size as needed
                                mb: 2}}/>
                            <Text textAlign="center" textColor="whiteAlpha.900" fontSize={{base:"11px", sm: "12"}}>1000/1000</Text>
                        
                        </Box>


                        <Box direction="column">
                            <a href="https://external-link-2.com" target="_blank" rel="noopener noreferrer">
                            <Image w={{base:"100px", sm: "120px"}} h={{base:"60px", sm: "80px"}} src="../launchpad logos/okx.png"/>
                            <Text textAlign="center" textColor="whiteAlpha.900">OKX</Text>
                            </a>
                            <Progress
                            value={progressValue5}
                            colorScheme="green"
                            hasStripe 
                            sx={{
                                borderRadius: 'full', // For rounded corners
                                height: '8px', // Adjust size as needed
                                mb: 2}}/>
                            <Text textAlign="center" textColor="whiteAlpha.900" fontSize={{base:"11px", sm: "12"}}>1000/1000</Text>
                        </Box>
                    </Flex>
                  </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default Card4;
