import React, { useRef, useState} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';
import { Box, IconButton, Image, Text, useBreakpointValue, Flex, SimpleGrid, VStack} from '@chakra-ui/react';
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import './Swiper.css';
import Card1 from './CardStyle1';

const cardContents = [
    {
    title: "Story",
    description: "\"Web3 Heiress\" is our premier collection, featuring an exclusive 1/1 artwork—there's only one piece in the entire world.\n\n This collection marks the beginning of our project, which has flourished through a collaboration with the renowned artist, Vincent Chu. Vincent brings his substantial following of over 100,000 subscribers from his YouTube channel, along with his vast array of artistic skills and experience. \n\nThis piece is meticulously hand-drawn, capturing the essence of beauty and grace in the form of a gentle woman whose presence seems to spring to life. Notably, she wears Hedera earrings, intricately designed to reflect the logo of the Hedera network, symbolizing our connection to the digital frontier.\n\n Web3 Heiress stands as the cornerstone of our project, heralding the innovative fusion of art and technology.",
    },
    {
    title: "Perks",
    description: "• Monthly & Annual Rewards.\n• Staking for $ANGRY Token.\n * Highest Yield.\n• Join the NFTs pool.\n * Full of 5% shares.\n• Lifetime whitelist guarantee.\n• Three quota redeemable NFTs of your choice.\n * Limited to one per collection.\n * Applicable to both current and future collections.\n • 1 NFT artwork request from Vincent Chu.\nDetails\n• Private Mint: TBA\n• Public Mint: TBA\n• Supply: 1 Pieces.\n• Type: Tier SSR.\n• Mint price: 49,000 HBAR.\n• Chain: Hedera network.",
    },
];  

const Card = ({ title, description, maxCharacterLimit }) => {
    const [isExpanded, setIsExpanded] = useState(false); // State to track expansion

    const cardSize = useBreakpointValue({ base: 'base', sm: 'sm', md: 'md', lg: 'lg' });
    const isPerksCard = title.toLowerCase() === "perks";

    const sizeStyles = {
        base: { width: '90vw', height: 'auto' },
        sm: { width: '480px', height: 'auto' },
        md: { width: '700px', height: 'auto' },
        lg: { width: '45vw', height: 'auto', maxWidth: '750px' },
    };

    const titleFontSize = { base: "17px", sm: "20px", md: "22px", lg: "24px" };
    const descriptionFontSize = { base: "14px", sm: "16px", md: "18px", lg: "18px" };
    const customFontSize = { base: "13px", sm: "14px", md: "16px", lg: "16px" };
    const customColor = "whiteAlpha.600";

    let textStyle = {};
    if (cardSize === 'base') {
        textStyle = { marginLeft: '-30px' };
    } else if (cardSize === 'lg') {
        textStyle = { marginLeft: '50px' };
    }

    const toggleExpansion = () => {
        setIsExpanded(!isExpanded);
    };

    const renderReadMore = () => {
        if (description.length <= maxCharacterLimit) return null;

        let readMoreStyle = {};
        if (isPerksCard && cardSize === 'lg') {
            readMoreStyle = { textAlign: 'left', marginLeft: '50px' };
        }

        return (
            <Text
                color="blue.400"
                fontSize={customFontSize[cardSize]}
                cursor="pointer"
                onClick={toggleExpansion}
                style={readMoreStyle}
            >
                {isExpanded ? "Show less" : "Read more"}
            </Text>
        );
    };

    const renderDescription = () => {
        let content = isExpanded ? description : `${description.substring(0, maxCharacterLimit)}...`;
        return content.split('\n').map((line, index) => {
            let lineStyle = {};
            let fontSizeStyle = descriptionFontSize; // Default font size
            let fontColor = "whiteAlpha.800"; // Default color

            if (line.trim().startsWith("•") || line.trim().startsWith("*")) {
                lineStyle = textStyle;
            }

            if (line.includes("Full of 5% shares") || line.includes("Highest Yield") ||
                line.includes("Limited to one per collection.") || line.includes("Applicable to both current and future collections.")) {
                fontSizeStyle = customFontSize;
                fontColor = customColor; 
            }

            if (line.startsWith("Details")) {
                return (
                    <Box key={index} width="100%" pb="1rem">
                        <Text 
                            color={fontColor}
                            fontSize={titleFontSize}
                            textAlign="center"
                            fontWeight="bold"
                            mt={3}
                            style={lineStyle}>
                            {line}
                        </Text>
                    </Box>
                );
            } else {
                return (
                    <Text 
                        key={index}
                        color={fontColor}
                        fontSize={fontSizeStyle}
                        style={{ textIndent: '40px', ...lineStyle }}>
                        {line}
                    </Text>
                );
            }
        });
    };

    return (
        <Box
            bg="transparent"
            color="white"
            pl="2rem"
            pr="2rem"
            borderRadius="md"
            boxShadow="md"
            {...sizeStyles[cardSize]}
        >
            <Text color="whiteAlpha.800" fontSize={titleFontSize} textAlign="center" fontWeight="bold" mt={3}>
                {title}
            </Text>
            <VStack align="start" spacing={2} pt="1rem">
                {renderDescription()}
                {renderReadMore()}
            </VStack>
        </Box>
    );
};

function SwiperComponentSSR() {

    const TEXT_STYLES = {
        color: "whiteAlpha.900",
        fontSize: { base: "24px", sm: "28px", md: "32px", lg: "36px" },
        fontWeight: "black",
        fontFamily: "'Lexend Peta', sans-serif"
    };

    const swiperRef = useRef(null);

    const handlePrevClick = () => {
        if (swiperRef.current) {
        swiperRef.current.swiper.slidePrev();
        }
    };

    const handleNextClick = () => {
        if (swiperRef.current) {
        swiperRef.current.swiper.slideNext();
        }
    };

    const paddingTopValue = useBreakpointValue({ base: '32px', sm: '48px' });

  return (
    <Box
        w="100vw"
        minW="100vw"
        minH="100vh"
        bg="#1C0D28"
        bgPos="center"
        bgSize="cover"
        bgRepeat="no-repeat"
        position="relative"
        overflowX="hidden"
        overflowY="hidden"
        css={{
            '@supports not (-webkit-touch-callout: none)': {
                width: '100vw',
            }
        }}
    >
    <Box>
      <Text {...TEXT_STYLES} textAlign="center" mt="1rem" id="web3-heiress-section" >SSR : Web3 Heiress</Text>

      <Swiper
        ref={swiperRef}
        style={{ paddingTop: paddingTopValue, paddingBottom: '30px' }}
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        slidesPerView={'auto'}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2.5,
        }}
        pagination={{ el: '.swiper-pagination', clickable: true }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
          clickable: true,
        }}
        modules={[EffectCoverflow, Pagination, Navigation]}
      >
            <SwiperSlide>        
                <Image src="/NFTBG/S1.jpg" alt="slide_image" boxShadow="0 0 25px #7851A9" />
            </SwiperSlide>

                <Box
                    position="relative" // Parent container for positioning
                    bottom="23rem"
                    left="0"
                    right="0"
                    zIndex="2"
                    width="100vw" 
                    display={{ base: 'none', sm: 'none' }}
                >
                    {/* Left Arrow Button */}
                    <Box position="absolute" pr="35rem" top="50%" left="50%" transform="translate(-50%, 50%)">
                        <IconButton
                            icon={<IoIosArrowBack size="70px" />}
                            aria-label="Previous"
                            onClick={handlePrevClick}
                            bg="transparent"
                            _hover={{ bg: 'transparent' }}
                            _active={{ bg: 'transparent' }}
                            color="#9435f2"
                        />
                    </Box>
                    
                    {/* Right Arrow Button */}
                    <Box position="absolute" pl="35rem" top="50%" left="50%" transform="translate(-50%, 50%)">
                        <IconButton
                            icon={<IoIosArrowForward size="70px" />}
                            aria-label="Next"
                            onClick={handleNextClick}
                            bg="transparent"
                            _hover={{ bg: 'transparent' }}
                            _active={{ bg: 'transparent' }}
                            color="#9435f2"
                        />
                    </Box>
                </Box>

                    <Box className="swiper-pagination"></Box>
                </Swiper>
            
                <Box display="flex" justifyContent="center">
                    <Card1/>
                </Box>

            {/* YouTube Video Embed */}
            <Box display="flex" justifyContent="center" pt="2rem">
                <Box
                    width={{base:"90vw", md:"560px"}}
                    height={{base:"60vw", md:"315px"}}
                    overflow="hidden" // This ensures the border radius applies to the iframe content
                    borderRadius="20px" // Adjust the border radius as needed
                    boxShadow="0px 0px 12px rgba(0,0,0,0.3)" // Optional: Adds a shadow for a lifted effect
                >
                    <iframe
                        width="100%"
                        height="100%"
                        src="https://www.youtube.com/embed/YbwUaJ72mug"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        style={{ borderRadius: '20px' }} // Ensure borderRadius is applied to iframe as well
                    ></iframe>
                </Box>
            </Box>

                <Flex justifyContent="center" alignItems="center" pt={{base: "2rem", sm:"4rem"}}>
                    <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={10} p={0}>
                    {cardContents.map((card, index) => (
                        <Card
                            key={index}
                            title={card.title}
                            description={card.description}
                            imageUrl={card.imageUrl}
                            maxCharacterLimit={card.title.toLowerCase() === "perks" ? 70 : 135} // Example: 200 for Perks, 135 for others
                            />
                        ))}
                    </SimpleGrid>
                </Flex>
            </Box>
            {/* S1*/ }
        </Box>
    );
}

export default SwiperComponentSSR;