import React, { useState, useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import Hero from './Hero';
import Products from './Products';
import Footer from './Footer';
import Roadmap from './Roadmap';
import OrientationWarning from './Rotate';
import News from './News';
import { useMediaQuery } from '@chakra-ui/react';
import Ecosystem from './Ecosystem';
import { Box, Text } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import Popnav from './Popnav';

function All() {
  const [isSmallScreen] = useMediaQuery("(max-width: 768px)");
  const [isLoading, setIsLoading] = useState(true);
  const animationContainer = useRef(null);

  // Framer Motion variants for fade-out effect
  const fadeOut = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
    exit: { opacity: 0 }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '../ANGRYiconloading.json' // Replace with the correct path to your JSON file
    });

    return () => anim.destroy(); // Optional clean up for unmounting
  }, []);

  const TEXT_STYLES = {
    color: "whiteAlpha.900",
    fontSize: { base: "30px", sm: "34px", md: "36px", lg: "42px" },
    fontWeight: "black",
    fontFamily: "'Lexend Peta', sans-serif",
  };

  const gradientStyle = {
    background: 'linear-gradient(to right, #9D50BB, #6E48AA, #E94057)', // Three-color gradient: Purple to Pink to a third color
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: '40px', // Adjust the size as needed
    fontWeight: 'bold',
    textAlign: 'center',
    margin: '20px'
  };

  if (isLoading) {
    return (
      <motion.div
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={fadeOut}
        transition={{ duration: 0.5 }}
      >
        <Box
          w="100vw"
          h="100vh"
          bg="#1C0D28"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <div ref={animationContainer} style={{ width: 100, height: 100 }}></div>
          <Text {...TEXT_STYLES} style={gradientStyle}>STAY ANGRY!</Text>
        </Box>
      </motion.div>
    );
  }

  return (
    <Box bg="#1C0D28">
      {isSmallScreen && <OrientationWarning />}
       {/* Add links after deal with launchpad + Add this section  <Popnav/> */}
      <Hero />
      <Products />
      <News />
      <Ecosystem />
      <Roadmap />
      <Footer />
    </Box>
  );
}

export default All;
