import React from "react";
import { Box, Flex, Link, Icon, Text } from "@chakra-ui/react";
import { FaDiscord, FaTelegram } from 'react-icons/fa';
import { AiOutlineInstagram } from 'react-icons/ai';
import { RiTwitterXFill } from 'react-icons/ri';

function Footer() {
  return (
    <Box 
        id="footer" 
        className="footer-clean" 
        padding="4rem" 
        backgroundColor="#0E0615" 
        bg="#1C0D28"
        color="whiteAlpha.800" 
        fontFamily="'Lexend Peta', sans-serif"
        w="100vw"
        bgPos="center"
        bgSize="cover"
        bgRepeat="no-repeat"
        position="relative"
        overflowX="hidden"
        overflowY="hidden"
        css={{
          '@supports not (-webkit-touch-callout: none)': {
            width: '100vw'
          }
        }}
      >
        
        <Box as="footer">
            <Flex direction={["column", "column", "row"]} align="center" justifyContent="center">
                {[ 

                ].map(section => (
                    <Box textAlign={["center", "center", "left"]} key={section.title}>
                        <Text fontSize="16px" fontWeight="bold" >{section.title}</Text>
                        <Box as="ul" padding="0" listStyleType="none" fontSize="14px" marginBottom="0">
                            {section.links.map(link => (
                                <Box as="li" key={link}>
                                    <Link color="#ffffff" opacity="0.8" _hover={{ opacity: "1", textDecoration: "underline" }}>{link}</Link>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                ))}

                <Flex direction="column" align="center" className="item social" >
                <Flex direction="column" align="center" className="item social" >
                    <Flex>
                        <a href="https://twitter.com/AngryHbarians" target="_blank" rel="noopener noreferrer" style={{ marginRight: '14px' }}>
                            <Icon as={RiTwitterXFill} fontSize="24px" color="#ffffff" />
                        </a>
                        <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" style={{ marginRight: '14px' }}>
                            <Icon as={AiOutlineInstagram} fontSize="24px" color="#ffffff" />
                        </a>
                        <a href="https://telegram.org/" target="_blank" rel="noopener noreferrer" style={{ marginRight: '14px' }}>
                            <Icon as={FaTelegram} fontSize="24px" color="#ffffff" />
                        </a>
                        <a href="https://discord.com/invite/2D2zPFcmBa" target="_blank" rel="noopener noreferrer">
                            <Icon as={FaDiscord} fontSize="24px" color="#ffffff" />
                        </a>
                    </Flex>
                    <Text fontSize="13px" opacity="0.6" marginTop="14px" marginBottom="0">Angry.web3@gmail.com</Text>
                    <Text fontSize="13px" opacity="0.6" marginTop="14px" marginBottom="0">Angry WEB3 © 2024</Text>
                </Flex>
                </Flex>
            </Flex>
        </Box>
    </Box>
  );
}

export default Footer;
