import React from 'react';
import { Box, Text, Flex, Image, Stack, Link } from '@chakra-ui/react';

const TEXT_STYLES = {
    color: "whiteAlpha.900",
    fontSize:{ base: "24px", sm: "28px", md: "36px", lg: "42px" },
    fontWeight: "black",
    fontFamily: "'Lexend Peta', sans-serif"
};  

const imageSize = {
    width: { base: "80px", sm: "110px", md: "120px", lg: "130px" },
    height: { base: "80px", sm: "110px", md: "120px", lg: "130px" }
};

const AuraImage = ({ src, alt, w, h, href, name }) => {
    
    return (
        <Link href={href} isExternal>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                p={{ base: "1", md: "2", lg: "3" }}
            >
                <Box
                    bgGradient="radial-gradient(ellipse, rgba(150, 50, 230, 0.3), transparent 65%)"
                    borderRadius="lg"
                    _hover={{
                        transform: "scale(1.08)",
                        transition: "transform 0.4s",
                    }}
                >
                    <Image src={src} alt={alt} w={w} h={h} />
                </Box>
                <Text
                    color="whiteAlpha.900"
                    mt="2"
                    textAlign="center" 
                    isTruncated
                    maxWidth="100%"
                    noOfLines={1}
                >
                    {name}
                </Text>
            </Box>
        </Link>
    );
};

const News = () => {


  return (
<Box
  w="100vw"
  bg="#1C0D28"
  bgPos="center"
  bgSize="cover"
  bgRepeat="no-repeat"
  position="relative"
  overflowX="hidden"
  overflowY="hidden"
  css={{
    '@supports not (-webkit-touch-callout: none)': {
      width: '100vw'
    }
  }}
>
        <Box display="flex" flexDirection="column" alignItems="center" pt="6rem">
            <Text {...TEXT_STYLES} pb="1rem">
                WHAT'S NEW?
            </Text>
            <Box>
                <Stack mb={{base: "3rem",sm:"3rem"}} spacing={4}>
                    <Text fontSize={{ base: "16px", sm: "20px", md: "22px", lg: "24px"}} textAlign="center" color= "whiteAlpha.900"> 1-29 Jan 2024</Text>
                    <Text fontSize={{ base: "13px", sm: "17px", md: "19px", lg: "21px"}} textAlign="center" color="whiteAlpha.700">• Private launch</Text>
                    <Text fontSize={{ base: "13px", sm: "17px", md: "19px", lg: "21px"}} textAlign="center" color="whiteAlpha.700">• Casino live: Beta</Text>
                </Stack>

                <Stack mt={{base: "0.5rem",sm:"1rem"}} spacing={4}>
                    <Text fontSize={{ base: "16px", sm: "20px", md: "22px", lg: "24px"}} textAlign="center" color= "whiteAlpha.900"> 30 Jan 2024</Text>
                    <Text fontSize={{ base: "13px", sm: "17px", md: "19px", lg: "21px"}} textAlign="center" color="whiteAlpha.700">• Officially launch the project.</Text>
                    <Text fontSize={{ base: "13px", sm: "17px", md: "19px", lg: "21px"}} textAlign="center" color="whiteAlpha.700">• Casino live: Demo.</Text>
                </Stack>
                <Stack mt={{base: "3rem",sm:"4rem"}} spacing={4}>
                    <Text fontSize={{ base: "16px", sm: "20px", md: "22px", lg: "24px"}} textAlign="center" color= "whiteAlpha.900">UPCOMING SCHEDULE</Text>
                    <Text fontSize={{ base: "13px", sm: "17px", md: "19px", lg: "21px"}} textAlign="center" color="whiteAlpha.700">• Casino fundrasing pass sales.</Text>
                    <Text fontSize={{ base: "13px", sm: "17px", md: "19px", lg: "21px"}} textAlign="center" color="whiteAlpha.700">• Casino officially live: March.</Text>
                    <Text fontSize={{ base: "13px", sm: "17px", md: "19px", lg: "21px"}}  fontStyle="italic" textAlign="center" color="whiteAlpha.700">* We're working on redesigning.</Text>
                </Stack>
            </Box>
        </Box>

        <Flex justifyContent="center" alignItems="center" mt={{base: "0.5rem" , sm: "2rem"}}>
        {/* Gradient Line */}
        <Box
            mt="1.5rem"
            ml={{ base: "2rem", sm: "1rem", md: "calc(4rem + (100vw - 100%) / 2)" }}
            mr={{ base: "2rem", sm: "1rem", md: "calc(4rem + (100vw - 100%) / 2)" }}
            w={{ base: "calc(100% - 4rem)", sm: "100%", md: "calc(100vw - 4rem)" }}
            h="1.6px"
            bg="linear-gradient(to right, #9647E5, #F84565)"
            borderRadius="50%"
            />
        </Flex>
        <Text
            id="Community"
            {...TEXT_STYLES}
            textAlign={{ base: "center", lg: "center" }} // Centered on small screens, right-aligned on medium and larger screens
            mr={{ base: "0", md: "0rem", lg: "0rem" }} // No margin on small screens, 1rem on medium, 4rem on large screens
            mt={{base: "2rem" , sm: "4rem"}}
            >
            COMMUNITY
        </Text>

        <Flex
            justifyContent="center" // Center horizontally
            alignItems="center" // Center vertically
            mt={{ base: "0.5rem", md: "1rem", lg: "1.5rem" }}
            direction={{ base: "column", lg: "row" }} // Column on small screens, row on large screens
            >
            {/* First Group */}
            <Stack
                direction={{ base: "column", md: "row" }}
                justifyContent="auto"
ห
                spacing={{ base: "0rem", md: "0.5rem", lg: "0" }}
            >   
                <AuraImage name="Coming soon" src="/Telegram.png" alt="Telegram" w={imageSize.width} h={imageSize.height} />
                <AuraImage name="Discord" src="/Discord.png" alt="Discord" w={imageSize.width} h={imageSize.height} href="https://discord.gg/Ym2P5vXagj"/>
            </Stack>

            {/* Second Group */}
            <Stack
                direction={{ base: "column", md: "row" }}
                justifyContent="auto"
                spacing={{ base: "0rem", md: "0.5rem", lg: "0rem" }}
            >
                <AuraImage name="Coming soon" src="/Instagram.png" alt="Instagram" w={imageSize.width} h={imageSize.height}  pb={{ base: "0", sm: "-1rem", md: "0", lg: "0" }}/>
                <AuraImage name="X(Twitter)" src="/X.png" alt="X" w={imageSize.width} h={imageSize.height} href="https://twitter.com/AngryHbarians" />  
            </Stack>
        </Flex>
    </Box>  
  );
};

export default News;
